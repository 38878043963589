import moment from "moment";

export default {
  namespaced:true,
  state: () => ({
    cropRotations: [],
    filter: {
      culture: '',
      sort: '',
      class: '',
      cleaning_date: '',
    },
    pagination: {
      current_page: 1,
      per_page: 15,
      total: 1,
      from: 1,
      to: 1,
      last_page: 1,
    },
    culturesDictionary: [
      'Пшеница мягкая озимая',
      'Пшеница твердая озимая',
      'Ячмень озимый',
      'Рожь озимая',
      'Тритикале озимый',
      'Пшеница мягкая яровая (Triticum aestivum L)',
      'Пшеница твердая яровая (Triticum durum Desf)',
      'Пшеница тургидум',
      'Ячмень яровой',
      'Овес',
      'Кукуруза на зерно',
      'Кукуруза на силос',
      'Сорго',
      'Просо',
      'Гречиха',
      'Рис',
      'Горох',
      'Чечевица',
      'Нут',
      'Подсолнечник',
      'Горчица, прочие масличные культуры',
      'Сафлор',
      'Соя',
      'Рапс озимый',
      'Рапс яровой',
      'Лен',
      'Свекла сахарная',
      'Хлопчатник',
      'Картофель',
      'Капуста',
      'Огурец',
      'Томаты',
      'Лук репчатый',
      'Чеснок',
      'Морковь',
      'Свекла столовая',
      'Петрушка',
      'Фасоль',
      'Перец',
      'Баклажан',
      'Арбуз',
      'Дыня',
      'Донник',
      'Суданская трава',
      'Сорго-суданковый гибрид',
      'Редька масличная',
      'Люцерна',
      'Клевер',
      'Эспарцет',
      'Житняк',
      'Кострец безостый',
      'Шелковица',
      'Яблоня',
      'Груша',
      'Айва',
      'Слива',
      'Вишня',
      'Абрикос',
      'Персик',
      'Смородина',
      'Малина',
      'Гранат',
      'Виноград',
      'Овсяница',
      'Клубника',
      'Рыжик озимый',
      'Тритикале яровой',
      'Тыква',
      'Кабачок',
      'Вика',
      'Лядвенец',
      'Пырей',
      'Райграс',
      'Тимофеевка',
      'Черешня',
      'Люпин',
      'Кукуруза на силос',
      'Земляника',
      'Кориандр',
      'Рыжик яровой',
      'Пырей сизый',
      'Голубика',
      'Фацелия',
      'Фестулолиум',
      'Могар',
      'Сурепица',
      'Мятлик луговой',
      'Ломкоколосник',
      'Ежа сборная',
      'Жузгун',
      'Крыжовник',
      'Ежевика',
      'Жимолость',
      'Облепиха',
      'Миндаль',
      'Фундук',
      'Грецкий орех',
      'Пары',
      'Многолетние травы прошлых лет',
      'Многолетние травы текущего года',
      'Однолетние травы',
    ],
    classDictionary: [
      'Зерновые культуры',
      'Зернобобовые культуры',
      'Кормовые культуры',
      'Масличные культуры',
      'Плодово-ягодные',
      'Бахчевые',
      'Овощные (открытый контур)',
      'Овощные (закрытый контур)',
      'Технические',
      'Пары',
    ],
    reproductionDictionary: [
      '1-репродукция',
      '2-репродукция',
      '3-репродукция',
      '4-репродукция',
      '5-репродукция',
      'Элитные',
      'Оригинальные',
      'Гибриды первого поколения',
    ],
    soilTreatmentDictionary: [
      'Классическая отвальная',
      'Безотвальная',
      'Нулевая обработка почвы (No-Till)',
      'Обработка почвы по технологии Strip-Till',
      'Минимальная обработка почвы',
      'Комбинированная',
    ],
    loading: false,
    error: null
  }),
  getters: {
    cropRotations: (state) => state.cropRotations,
    pagination: (state) => state.pagination,
    filter: (state) => state.filter,
    culturesDictionary: state => state.culturesDictionary,
    classDictionary: state => state.classDictionary,
    reproductionDictionary: state => state.reproductionDictionary,
    soilTreatmentDictionary: state => state.soilTreatmentDictionary,
    loading: state => state.loading,
    error: state => state.error,
    hasFilters: state => state.filter.class || state.filter.sort || state.filter.cleaning_date || state.filter.culture
  },
  mutations: {
    SET_CROP_ROTATIONS(state, data){
      data.map((item) => {
        if (item.cleaning_date) {
          item.cleaning_date = moment.utc(item.cleaning_date).local().format("YYYY-MM-DD");
        }
        return item;
      });

      state.cropRotations = data
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    },
    SET_PAGE(state, page) {
      state.pagination.current_page = page
    },
    CLEAR_FILTER(state) {
      state.filter.culture = '';
      state.filter.sort = '';
      state.filter.class = '';
      state.filter.cleaning_date = '';
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_ERROR(state, error) {
      state.error = error;
    }
  },
  actions: {
    async getCropRotations({commit, state}) {
      commit('SET_LOADING', true)
      commit('SET_ERROR', null)
      try {
        const {data} = await this.$axios.get('api/wmsh/crop_rotations', {
          params: {
            page: state.pagination.current_page,
            ...state.filter
          }
        });

        commit('SET_CROP_ROTATIONS', data.data);
        commit('SET_PAGINATION', data.meta);
      } catch (e) {
        commit('SET_ERROR', e?.response?.data?.message || e?.response?.message || e?.message || "Произошла ошибка повторите попытку позднее")
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async createCropRotation({commit, state}, data) {
      try {
        await this.$axios.post('api/wmsh/crop_rotations/create', data)
      } catch (e) {
        throw e;
      }
    },
    async editCropRotation({commit, state}, data) {
      try {
        await this.$axios.post('api/wmsh/crop_rotations/edit', data)
      } catch (e) {
        throw e;
      }
    },
  }
}