//
//
//
//

const components = {
  Billing: () =>
    import("./Billing.vue" /* webpackChunkName: "icons/Billing" */),
  ChevronDown: () =>
    import("./ChevronDown.vue" /* webpackChunkName: "icons/ChevronDown" */),
  ClarityNotification: () =>
    import(
      "./ClarityNotification.vue" /* webpackChunkName: "icons/ClarityNotification" */
    ),
  Cow: () => import("./Cow.vue" /* webpackChunkName: "icons/Cow" */),
  Drone: () => import("./Drone.vue" /* webpackChunkName: "icons/Drone" */),
  Farm: () => import("./Farm.vue" /* webpackChunkName: "icons/Farm" */),
  SignOut: () =>
    import("./SignOut.vue" /* webpackChunkName: "icons/SignOut" */),
  Tractor: () =>
    import("./Tractor.vue" /* webpackChunkName: "icons/Tractor" */),
  UserAvatar: () =>
    import("./UserAvatar.vue" /* webpackChunkName: "icons/UserAvatar" */),
  DocumentBulletList: () =>
    import(
      "./DocumentBulletList.vue" /* webpackChunkName: "icons/DocumentBulletList" */
    ),
  UploadPhoto: () =>
    import("./UploadPhoto.vue" /* webpackChunkName: "icons/UploadPhoto" */),
  SquareSlider: () =>
    import("./SquareSlider.vue" /* webpackChunkName: "icons/SquareSlider" */),
  NextPage: () =>
    import("./NextPage.vue" /* webpackChunkName: "icons/NextPage" */),
  PrevPage: () =>
    import("./PrevPage.vue" /* webpackChunkName: "icons/PrevPage" */),
  Refresh: () =>
    import("./Refresh.vue" /* webpackChunkName: "icons/Refresh" */),
  Plus: () => import("./Plus.vue" /* webpackChunkName: "icons/Plus" */),
  Trash: () => import("./Trash.vue" /* webpackChunkName: "icons/Trash" */),
  Pencil: () => import("./Pencil.vue" /* webpackChunkName: "icons/Pencil" */),
  Calendar: () =>
    import("./Calendar.vue" /* webpackChunkName: "icons/Calendar" */),
  ChevronBack: () =>
    import("./ChevronBack.vue" /* webpackChunkName: "icons/ChevronBack" */),
  Check: () => import("./Check.vue" /* webpackChunkName: "icons/Check" */),
  CloseFill: () =>
    import("./CloseFill.vue" /* webpackChunkName: "icons/CloseFill" */),
  HistoryLine: () =>
    import("./HistoryLine" /* webpackChunkName: "icons/HistoryLine" */),
  CropRotation: () =>
    import("./CropRotation" /* webpackChunkName: "icons/CropRotation" */),
};
export default {
  components,
  props: {
    iconName: {
      type: String,
      required: true,
    },
  },
};
