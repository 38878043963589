import { SET_ERRORS } from "~/store/process/mutation-types";

export default {
  namespaced:true,
  state: () => ({
    historicalData: [],
    historicalInitData: [],
    searchingValue: '',
    filter: {
      searchValue: '',
      category: ''
    },
    categories: [
      {
        value: 'animal',
        name: {
          'ru': 'животные',
          'kk': 'жануарлар'
        }
      },
      {
        value: 'aqua-culture',
        name: {
          'ru':'аква культура',
          'kk': 'аква мәдениет'
        }
      },
      {
        value: 'fertilizer',
        name: {
          'ru': 'удобрение',
          'kk': 'тыңайтқыш'
        }
      },
      {
        value: 'hectare',
        name: {
          'ru': 'гектарные субсидии',
          'kk': 'гектарлық субсидиялар'
        }
      },
      {
        value: 'herbicide',
        name: {
          'ru': 'гербициды',
          'kk': 'гербицидтер'
        }
      },
      {
        value: 'invest',
        name: {
          'ru': 'инвестиционные субсидии',
          'kk': 'инвестициялық субсидиялар'
        }
      },
      {
        value: 'perennial',
        name: {
          'ru': 'многолетние насаждения',
          'kk': 'көпжылдық екпелер'
        }
      },
      {
        value: 'product-recycle',
        name: {
          'ru': 'переработка продукции',
          'kk': 'өнімді қайта өңдеу'
        }
      },
      {
        value: 'seed',
        name: {
          'ru': 'семена',
          'kk': 'тұқымдар'
        }
      },
      {
        value: 'water',
        name: {
          'ru': 'вода',
          'kk': "су"
        }
      },
    ],
    pagination: {
      current_page: 1,
      per_page: 15,
      total: 1,
      from: 1,
      to: 1,
      last_page: 1,
    },
    loading: false,
    error: null
  }),
  getters: {
    historicalData: (state) => state.historicalData,
    historicalInitData: (state) => state.historicalInitData,
    categories: (state) => state.categories,
    filter: (state) => state.filter,
    pagination: (state) => {
      let total = state.historicalData.length
      let {current_page, per_page, from, to, last_page} = state.pagination
      return {
        current_page,
        per_page,
        total,
        from: (current_page - 1) * per_page || from,
        to: current_page * per_page || to,
        last_page: Math.ceil(total / per_page) || last_page,
      }
    },
    loading: state => state.loading,
    error: state => state.error
  },
  mutations: {
    SET_HISTORICAL_INIT_DATA(state, data) {
      state.historicalInitData = data
    },
    SET_HISTORICAL_DATA(state, data) {
      state.historicalData = data
    },
    SET_PAGE(state, page) {
      state.pagination.current_page = page
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_ERROR(state, error) {
      state.error = error
    }
  },
  actions: {
    async getHistoricalData({commit, state}) {
      commit("SET_LOADING", true)
      commit('SET_ERROR', null)
      try {
        const {data} = await this.$axios.get('api/wmsh/shep_info/historical_data');

        await commit('SET_HISTORICAL_INIT_DATA', data.qoldat_data || []);
        await commit('SET_HISTORICAL_DATA', data.qoldat_data || []);
      } catch (e) {
        commit('SET_ERROR', e?.response?.data?.message || e?.response?.message || e?.message || "Произошла ошибка повторите попытку позднее")
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async filterHistoricalData({commit, state, getters}) {
      let { filter } = state;
      let { historicalInitData } = getters;
      let historicalData = historicalInitData;

      if (filter.category) {
        historicalData = historicalData.filter(item => item.categoryName === filter.category)
      }

      if (filter.searchValue) {
        historicalData = historicalData.filter((item) => {
          const numbers = item.applicationNumber.toString().toLowerCase();
          const searchTerm = filter.searchValue.toLowerCase();

          return numbers.includes(searchTerm)
        });
      }

      await commit('SET_HISTORICAL_DATA', historicalData)
    }
  }
}