export default {
  namespaced:true,
  state: () => ({
    bankAccounts: [],
    searchingValue: '',
    selectedAppPeriod: '',
    loading: false,
  }),
  getters: {
    bankAccounts: (state) => state.bankAccounts,
    loading: state => state.loading
  },
  mutations: {
    SET_BANK_ACCOUNTS(state, data) {
      state.bankAccounts = data
    },
    SET_PAGINATION_PAGES_COUNT(state, pagesCount) {
      state.paginationPagesCount = pagesCount
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    }
  },
  actions: {
    async getBankAccounts({ commit, state }) {
      commit('SET_LOADING', true)
      try {
        const {data} = await this.$axios.get('api/wmsh/banking_details', {
          params: {
            page: state.paginationActivePage
          }
        });

        commit('SET_BANK_ACCOUNTS', data.data);
      } catch (e) {
        throw e;
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async createBankAccount({ commit, state }, data) {
      try {
        await this.$axios.post('api/wmsh/banking_details', data)
      } catch (e) {
        throw e;
      }
    },
    async updateBankAccount({ commit, state }, {id, data}) {
      commit('SET_LOADING', true)
      try {
        await this.$axios.put(`api/wmsh/banking_details/${id}`, data)
      } catch (e) {
        throw e;
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async deleteBankAccount({ commit}, id) {
      commit('SET_LOADING', true)
      try {
        await this.$axios.delete(`api/wmsh/banking_details/${id}`)
      } catch (e) {
        throw e;
      } finally {
        commit('SET_LOADING', false)
      }
    },
  }
}