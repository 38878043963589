export default {
  namespaced:true,
  state:  () => ({
    userUlInfo: {}
  }),
  getters:  {
    userUlInfo: (state) => state.userUlInfo,
  },
  mutations: {
    SET_USER_UL_INFO(state, data) {
      state.userUlInfo = data
    },
  },
  actions:  {
    async updatePassword({ commit, state }, {role, data}) {
      console.log(data);
      try {
        await this.$axios.post(`api/password/edit/${role}`,  data)
      } catch (e) {
        throw e;
      }
    },
    async updateContactData({ commit, state }, {role, data}) {
      try {
        await this.$axios.post(`api/edit/${role}`,  data)
      } catch (e) {
        throw e;
      }
    },
    async getUlInfo({ commit }) {
      try {
        const { data } = await this.$axios.get('/api/wmsh/shep_info/ul-info')
        commit('SET_USER_UL_INFO', data)
      } catch (e) {
        throw e;
      }
    },
  }
}