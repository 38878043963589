import { render, staticRenderFns } from "./SidebarMenuIcon.vue?vue&type=template&id=00c50c2a&"
import script from "./SidebarMenuIcon.vue?vue&type=script&lang=js&"
export * from "./SidebarMenuIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ApplicantCabinetIconsIcon: require('/home/tadmin/var/www/msh/ui/modules/Wmsh/components/ApplicantCabinet/Icons/Icon.vue').default})
