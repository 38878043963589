import animals from './animals'
import apps from './apps'
import bankAccounts from './bankAccounts'
import cropRotations from './cropRotations'
import digitalFields from './digitalFields'
import profile from './profile'
import qoldauHistoricalData from './qoldauHistoricalData'
import territories from './territories'
import vehicles from './vehicles'

export default {
  namespaced: true,
  modules: {
    animals,
    apps,
    bankAccounts,
    cropRotations,
    digitalFields,
    profile,
    qoldauHistoricalData,
    territories,
    vehicles
  }
}