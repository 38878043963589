export const Budget = () => import('../../modules/Wmsh/components/Budget.vue' /* webpackChunkName: "components/budget" */).then(c => wrapFunctional(c.default || c))
export const Giss2iframe = () => import('../../modules/Wmsh/components/Giss2iframe.vue' /* webpackChunkName: "components/giss2iframe" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetEmployeeCabinetHeader = () => import('../../modules/Wmsh/components/ApplicantCabinet/EmployeeCabinetHeader.vue' /* webpackChunkName: "components/applicant-cabinet-employee-cabinet-header" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetEmployeeCabinetSidebar = () => import('../../modules/Wmsh/components/ApplicantCabinet/EmployeeCabinetSidebar.vue' /* webpackChunkName: "components/applicant-cabinet-employee-cabinet-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetHeader = () => import('../../modules/Wmsh/components/ApplicantCabinet/Header.vue' /* webpackChunkName: "components/applicant-cabinet-header" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetNotificationSidebar = () => import('../../modules/Wmsh/components/ApplicantCabinet/NotificationSidebar.vue' /* webpackChunkName: "components/applicant-cabinet-notification-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetPagination = () => import('../../modules/Wmsh/components/ApplicantCabinet/Pagination.vue' /* webpackChunkName: "components/applicant-cabinet-pagination" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetSidebar = () => import('../../modules/Wmsh/components/ApplicantCabinet/Sidebar.vue' /* webpackChunkName: "components/applicant-cabinet-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetSpinner = () => import('../../modules/Wmsh/components/ApplicantCabinet/Spinner.vue' /* webpackChunkName: "components/applicant-cabinet-spinner" */).then(c => wrapFunctional(c.default || c))
export const ArmFooter = () => import('../../modules/Wmsh/components/Arm/Footer.vue' /* webpackChunkName: "components/arm-footer" */).then(c => wrapFunctional(c.default || c))
export const ArmHeader = () => import('../../modules/Wmsh/components/Arm/Header.vue' /* webpackChunkName: "components/arm-header" */).then(c => wrapFunctional(c.default || c))
export const ArmPriceobsh = () => import('../../modules/Wmsh/components/Arm/Priceobsh.vue' /* webpackChunkName: "components/arm-priceobsh" */).then(c => wrapFunctional(c.default || c))
export const BaseFooter = () => import('../../modules/Wmsh/components/Base/Footer.vue' /* webpackChunkName: "components/base-footer" */).then(c => wrapFunctional(c.default || c))
export const BaseHeader = () => import('../../modules/Wmsh/components/Base/Header.vue' /* webpackChunkName: "components/base-header" */).then(c => wrapFunctional(c.default || c))
export const BaseHelper = () => import('../../modules/Wmsh/components/Base/Helper.vue' /* webpackChunkName: "components/base-helper" */).then(c => wrapFunctional(c.default || c))
export const BaseHelperIcons = () => import('../../modules/Wmsh/components/Base/HelperIcons.vue' /* webpackChunkName: "components/base-helper-icons" */).then(c => wrapFunctional(c.default || c))
export const BaseLogin = () => import('../../modules/Wmsh/components/Base/Login.vue' /* webpackChunkName: "components/base-login" */).then(c => wrapFunctional(c.default || c))
export const BaseMain = () => import('../../modules/Wmsh/components/Base/Main.vue' /* webpackChunkName: "components/base-main" */).then(c => wrapFunctional(c.default || c))
export const BaseMetrika = () => import('../../modules/Wmsh/components/Base/Metrika.vue' /* webpackChunkName: "components/base-metrika" */).then(c => wrapFunctional(c.default || c))
export const BaseQoldauContracts = () => import('../../modules/Wmsh/components/Base/QoldauContracts.vue' /* webpackChunkName: "components/base-qoldau-contracts" */).then(c => wrapFunctional(c.default || c))
export const BaseServiceCategory = () => import('../../modules/Wmsh/components/Base/ServiceCategory.vue' /* webpackChunkName: "components/base-service-category" */).then(c => wrapFunctional(c.default || c))
export const DocWrapper = () => import('../../modules/Wmsh/components/Doc/Wrapper.vue' /* webpackChunkName: "components/doc-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ModalCommissionReviews = () => import('../../modules/Wmsh/components/Modal/CommissionReviews.vue' /* webpackChunkName: "components/modal-commission-reviews" */).then(c => wrapFunctional(c.default || c))
export const ModalConfirmReview = () => import('../../modules/Wmsh/components/Modal/ConfirmReview.vue' /* webpackChunkName: "components/modal-confirm-review" */).then(c => wrapFunctional(c.default || c))
export const ModalCreateInvoice = () => import('../../modules/Wmsh/components/Modal/CreateInvoice.vue' /* webpackChunkName: "components/modal-create-invoice" */).then(c => wrapFunctional(c.default || c))
export const ModalIncreaseShare = () => import('../../modules/Wmsh/components/Modal/IncreaseShare.vue' /* webpackChunkName: "components/modal-increase-share" */).then(c => wrapFunctional(c.default || c))
export const ProcessDirectionBlock = () => import('../../modules/Wmsh/components/Process/DirectionBlock.vue' /* webpackChunkName: "components/process-direction-block" */).then(c => wrapFunctional(c.default || c))
export const ProcessDocBlock = () => import('../../modules/Wmsh/components/Process/DocBlock.vue' /* webpackChunkName: "components/process-doc-block" */).then(c => wrapFunctional(c.default || c))
export const ProcessInputBlock = () => import('../../modules/Wmsh/components/Process/InputBlock.vue' /* webpackChunkName: "components/process-input-block" */).then(c => wrapFunctional(c.default || c))
export const ProcessMain = () => import('../../modules/Wmsh/components/Process/Main.vue' /* webpackChunkName: "components/process-main" */).then(c => wrapFunctional(c.default || c))
export const ProcessNavbar = () => import('../../modules/Wmsh/components/Process/Navbar.vue' /* webpackChunkName: "components/process-navbar" */).then(c => wrapFunctional(c.default || c))
export const QoldauArmMain = () => import('../../modules/Wmsh/components/Qoldau/ArmMain.vue' /* webpackChunkName: "components/qoldau-arm-main" */).then(c => wrapFunctional(c.default || c))
export const QoldauBaseMain = () => import('../../modules/Wmsh/components/Qoldau/BaseMain.vue' /* webpackChunkName: "components/qoldau-base-main" */).then(c => wrapFunctional(c.default || c))
export const QoldauCreateInvoiceButton = () => import('../../modules/Wmsh/components/Qoldau/CreateInvoiceButton.vue' /* webpackChunkName: "components/qoldau-create-invoice-button" */).then(c => wrapFunctional(c.default || c))
export const QoldauInvoiceModal = () => import('../../modules/Wmsh/components/Qoldau/InvoiceModal.vue' /* webpackChunkName: "components/qoldau-invoice-modal" */).then(c => wrapFunctional(c.default || c))
export const RegistryAll = () => import('../../modules/Wmsh/components/Registry/All.vue' /* webpackChunkName: "components/registry-all" */).then(c => wrapFunctional(c.default || c))
export const RegistryOnPay = () => import('../../modules/Wmsh/components/Registry/OnPay.vue' /* webpackChunkName: "components/registry-on-pay" */).then(c => wrapFunctional(c.default || c))
export const AdminControlMain = () => import('../../modules/Wmsh/components/Admin/Control/Main.vue' /* webpackChunkName: "components/admin-control-main" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetAnimalsFilters = () => import('../../modules/Wmsh/components/ApplicantCabinet/Animals/Filters.vue' /* webpackChunkName: "components/applicant-cabinet-animals-filters" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetAnimalsModal = () => import('../../modules/Wmsh/components/ApplicantCabinet/Animals/Modal.vue' /* webpackChunkName: "components/applicant-cabinet-animals-modal" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetAnimalsTable = () => import('../../modules/Wmsh/components/ApplicantCabinet/Animals/Table.vue' /* webpackChunkName: "components/applicant-cabinet-animals-table" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetBankDetailsAddBankingAccountModal = () => import('../../modules/Wmsh/components/ApplicantCabinet/BankDetails/AddBankingAccountModal.vue' /* webpackChunkName: "components/applicant-cabinet-bank-details-add-banking-account-modal" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetBankDetailsForm = () => import('../../modules/Wmsh/components/ApplicantCabinet/BankDetails/Form.vue' /* webpackChunkName: "components/applicant-cabinet-bank-details-form" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetCropRotationAddCropRotation = () => import('../../modules/Wmsh/components/ApplicantCabinet/CropRotation/AddCropRotation.vue' /* webpackChunkName: "components/applicant-cabinet-crop-rotation-add-crop-rotation" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetCropRotationFilterModal = () => import('../../modules/Wmsh/components/ApplicantCabinet/CropRotation/FilterModal.vue' /* webpackChunkName: "components/applicant-cabinet-crop-rotation-filter-modal" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetCropRotationFilters = () => import('../../modules/Wmsh/components/ApplicantCabinet/CropRotation/Filters.vue' /* webpackChunkName: "components/applicant-cabinet-crop-rotation-filters" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetCropRotationTable = () => import('../../modules/Wmsh/components/ApplicantCabinet/CropRotation/Table.vue' /* webpackChunkName: "components/applicant-cabinet-crop-rotation-table" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetElectronicMapFieldsTable = () => import('../../modules/Wmsh/components/ApplicantCabinet/ElectronicMapFields/ElectronicMapFieldsTable.vue' /* webpackChunkName: "components/applicant-cabinet-electronic-map-fields-table" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetEmployeeProfileForm = () => import('../../modules/Wmsh/components/ApplicantCabinet/EmployeeProfile/EmployeeProfileForm.vue' /* webpackChunkName: "components/applicant-cabinet-employee-profile-form" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsBilling = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/Billing.vue' /* webpackChunkName: "components/applicant-cabinet-icons-billing" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsCalendar = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/Calendar.vue' /* webpackChunkName: "components/applicant-cabinet-icons-calendar" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsCheck = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/Check.vue' /* webpackChunkName: "components/applicant-cabinet-icons-check" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsChevronBack = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/ChevronBack.vue' /* webpackChunkName: "components/applicant-cabinet-icons-chevron-back" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsChevronDown = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/ChevronDown.vue' /* webpackChunkName: "components/applicant-cabinet-icons-chevron-down" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsClarityNotification = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/ClarityNotification.vue' /* webpackChunkName: "components/applicant-cabinet-icons-clarity-notification" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsCloseFill = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/CloseFill.vue' /* webpackChunkName: "components/applicant-cabinet-icons-close-fill" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsCow = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/Cow.vue' /* webpackChunkName: "components/applicant-cabinet-icons-cow" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsCropRotation = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/CropRotation.vue' /* webpackChunkName: "components/applicant-cabinet-icons-crop-rotation" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsDocumentBulletList = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/DocumentBulletList.vue' /* webpackChunkName: "components/applicant-cabinet-icons-document-bullet-list" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsDrone = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/Drone.vue' /* webpackChunkName: "components/applicant-cabinet-icons-drone" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsFarm = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/Farm.vue' /* webpackChunkName: "components/applicant-cabinet-icons-farm" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsHistoryLine = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/HistoryLine.vue' /* webpackChunkName: "components/applicant-cabinet-icons-history-line" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsIcon = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/Icon.vue' /* webpackChunkName: "components/applicant-cabinet-icons-icon" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsNextPage = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/NextPage.vue' /* webpackChunkName: "components/applicant-cabinet-icons-next-page" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsPencil = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/Pencil.vue' /* webpackChunkName: "components/applicant-cabinet-icons-pencil" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsPlus = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/Plus.vue' /* webpackChunkName: "components/applicant-cabinet-icons-plus" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsPrevPage = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/PrevPage.vue' /* webpackChunkName: "components/applicant-cabinet-icons-prev-page" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsRefresh = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/Refresh.vue' /* webpackChunkName: "components/applicant-cabinet-icons-refresh" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsSignOut = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/SignOut.vue' /* webpackChunkName: "components/applicant-cabinet-icons-sign-out" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsSquareSlider = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/SquareSlider.vue' /* webpackChunkName: "components/applicant-cabinet-icons-square-slider" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsTractor = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/Tractor.vue' /* webpackChunkName: "components/applicant-cabinet-icons-tractor" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsTrash = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/Trash.vue' /* webpackChunkName: "components/applicant-cabinet-icons-trash" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsUpdatePhoto = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/UpdatePhoto.vue' /* webpackChunkName: "components/applicant-cabinet-icons-update-photo" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsUploadPhoto = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/UploadPhoto.vue' /* webpackChunkName: "components/applicant-cabinet-icons-upload-photo" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetIconsUserAvatar = () => import('../../modules/Wmsh/components/ApplicantCabinet/Icons/UserAvatar.vue' /* webpackChunkName: "components/applicant-cabinet-icons-user-avatar" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetProfileChangePasswordModal = () => import('../../modules/Wmsh/components/ApplicantCabinet/Profile/ChangePasswordModal.vue' /* webpackChunkName: "components/applicant-cabinet-profile-change-password-modal" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetProfileUpdateContactsModal = () => import('../../modules/Wmsh/components/ApplicantCabinet/Profile/UpdateContactsModal.vue' /* webpackChunkName: "components/applicant-cabinet-profile-update-contacts-modal" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetQoldauHitoricalDataFilters = () => import('../../modules/Wmsh/components/ApplicantCabinet/QoldauHitoricalData/Filters.vue' /* webpackChunkName: "components/applicant-cabinet-qoldau-hitorical-data-filters" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetQoldauHitoricalDataStatus = () => import('../../modules/Wmsh/components/ApplicantCabinet/QoldauHitoricalData/Status.vue' /* webpackChunkName: "components/applicant-cabinet-qoldau-hitorical-data-status" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetQoldauHitoricalDataTable = () => import('../../modules/Wmsh/components/ApplicantCabinet/QoldauHitoricalData/Table.vue' /* webpackChunkName: "components/applicant-cabinet-qoldau-hitorical-data-table" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetServiceHistoryAppStatus = () => import('../../modules/Wmsh/components/ApplicantCabinet/ServiceHistory/AppStatus.vue' /* webpackChunkName: "components/applicant-cabinet-service-history-app-status" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetServiceHistoryAppStep = () => import('../../modules/Wmsh/components/ApplicantCabinet/ServiceHistory/AppStep.vue' /* webpackChunkName: "components/applicant-cabinet-service-history-app-step" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetServiceHistoryFilters = () => import('../../modules/Wmsh/components/ApplicantCabinet/ServiceHistory/Filters.vue' /* webpackChunkName: "components/applicant-cabinet-service-history-filters" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetServiceHistoryResultModal = () => import('../../modules/Wmsh/components/ApplicantCabinet/ServiceHistory/ResultModal.vue' /* webpackChunkName: "components/applicant-cabinet-service-history-result-modal" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetServiceHistorySelectPeriod = () => import('../../modules/Wmsh/components/ApplicantCabinet/ServiceHistory/SelectPeriod.vue' /* webpackChunkName: "components/applicant-cabinet-service-history-select-period" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetServiceHistoryTable = () => import('../../modules/Wmsh/components/ApplicantCabinet/ServiceHistory/Table.vue' /* webpackChunkName: "components/applicant-cabinet-service-history-table" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetServicesForm = () => import('../../modules/Wmsh/components/ApplicantCabinet/Services/ServicesForm.vue' /* webpackChunkName: "components/applicant-cabinet-services-form" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetSidebarMenu = () => import('../../modules/Wmsh/components/ApplicantCabinet/Sidebar/SidebarMenu.vue' /* webpackChunkName: "components/applicant-cabinet-sidebar-menu" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetSidebarMenuBadge = () => import('../../modules/Wmsh/components/ApplicantCabinet/Sidebar/SidebarMenuBadge.vue' /* webpackChunkName: "components/applicant-cabinet-sidebar-menu-badge" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetSidebarMenuIcon = () => import('../../modules/Wmsh/components/ApplicantCabinet/Sidebar/SidebarMenuIcon.vue' /* webpackChunkName: "components/applicant-cabinet-sidebar-menu-icon" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetSidebarMenuItem = () => import('../../modules/Wmsh/components/ApplicantCabinet/Sidebar/SidebarMenuItem.vue' /* webpackChunkName: "components/applicant-cabinet-sidebar-menu-item" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetSidebarMenuLink = () => import('../../modules/Wmsh/components/ApplicantCabinet/Sidebar/SidebarMenuLink.vue' /* webpackChunkName: "components/applicant-cabinet-sidebar-menu-link" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetTerritoriesFilters = () => import('../../modules/Wmsh/components/ApplicantCabinet/Territories/Filters.vue' /* webpackChunkName: "components/applicant-cabinet-territories-filters" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetTerritoriesTable = () => import('../../modules/Wmsh/components/ApplicantCabinet/Territories/Table.vue' /* webpackChunkName: "components/applicant-cabinet-territories-table" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetVehiclesFilters = () => import('../../modules/Wmsh/components/ApplicantCabinet/Vehicles/Filters.vue' /* webpackChunkName: "components/applicant-cabinet-vehicles-filters" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetVehiclesModal = () => import('../../modules/Wmsh/components/ApplicantCabinet/Vehicles/Modal.vue' /* webpackChunkName: "components/applicant-cabinet-vehicles-modal" */).then(c => wrapFunctional(c.default || c))
export const ApplicantCabinetVehiclesTable = () => import('../../modules/Wmsh/components/ApplicantCabinet/Vehicles/Table.vue' /* webpackChunkName: "components/applicant-cabinet-vehicles-table" */).then(c => wrapFunctional(c.default || c))
export const ArmAppListIncome = () => import('../../modules/Wmsh/components/Arm/App/ListIncome.vue' /* webpackChunkName: "components/arm-app-list-income" */).then(c => wrapFunctional(c.default || c))
export const ArmServiceList = () => import('../../modules/Wmsh/components/Arm/Service/List.vue' /* webpackChunkName: "components/arm-service-list" */).then(c => wrapFunctional(c.default || c))
export const BaseAppList = () => import('../../modules/Wmsh/components/Base/App/List.vue' /* webpackChunkName: "components/base-app-list" */).then(c => wrapFunctional(c.default || c))
export const BaseDashbordsMain = () => import('../../modules/Wmsh/components/Base/Dashbords/Main.vue' /* webpackChunkName: "components/base-dashbords-main" */).then(c => wrapFunctional(c.default || c))
export const BaseMainNews = () => import('../../modules/Wmsh/components/Base/Main/News.vue' /* webpackChunkName: "components/base-main-news" */).then(c => wrapFunctional(c.default || c))
export const BaseMainScreen = () => import('../../modules/Wmsh/components/Base/Main/Screen.vue' /* webpackChunkName: "components/base-main-screen" */).then(c => wrapFunctional(c.default || c))
export const BaseServiceAll = () => import('../../modules/Wmsh/components/Base/Service/All.vue' /* webpackChunkName: "components/base-service-all" */).then(c => wrapFunctional(c.default || c))
export const BaseServiceCircleIcon = () => import('../../modules/Wmsh/components/Base/Service/CircleIcon.vue' /* webpackChunkName: "components/base-service-circle-icon" */).then(c => wrapFunctional(c.default || c))
export const BaseServiceInfo = () => import('../../modules/Wmsh/components/Base/Service/Info.vue' /* webpackChunkName: "components/base-service-info" */).then(c => wrapFunctional(c.default || c))
export const BaseServiceList = () => import('../../modules/Wmsh/components/Base/Service/List.vue' /* webpackChunkName: "components/base-service-list" */).then(c => wrapFunctional(c.default || c))
export const BaseServiceSelectArrowIcon = () => import('../../modules/Wmsh/components/Base/Service/SelectArrowIcon.vue' /* webpackChunkName: "components/base-service-select-arrow-icon" */).then(c => wrapFunctional(c.default || c))
export const BaseServiceTreeView = () => import('../../modules/Wmsh/components/Base/Service/TreeView.vue' /* webpackChunkName: "components/base-service-tree-view" */).then(c => wrapFunctional(c.default || c))
export const BaseServiceTreeViewItem = () => import('../../modules/Wmsh/components/Base/Service/TreeViewItem.vue' /* webpackChunkName: "components/base-service-tree-view-item" */).then(c => wrapFunctional(c.default || c))
export const BaseDashbordsTypesBar = () => import('../../modules/Wmsh/components/Base/Dashbords/Types/Bar.vue' /* webpackChunkName: "components/base-dashbords-types-bar" */).then(c => wrapFunctional(c.default || c))
export const BaseDashbordsTypesDonut = () => import('../../modules/Wmsh/components/Base/Dashbords/Types/Donut.vue' /* webpackChunkName: "components/base-dashbords-types-donut" */).then(c => wrapFunctional(c.default || c))
export const BaseDashbordsTypesHorizontalBar = () => import('../../modules/Wmsh/components/Base/Dashbords/Types/HorizontalBar.vue' /* webpackChunkName: "components/base-dashbords-types-horizontal-bar" */).then(c => wrapFunctional(c.default || c))
export const BaseDashbordsTypesPie = () => import('../../modules/Wmsh/components/Base/Dashbords/Types/Pie.vue' /* webpackChunkName: "components/base-dashbords-types-pie" */).then(c => wrapFunctional(c.default || c))
export const Map = () => import('../../uicore/components/Map.vue' /* webpackChunkName: "components/map" */).then(c => wrapFunctional(c.default || c))
export const NumberFormat = () => import('../../uicore/components/NumberFormat.vue' /* webpackChunkName: "components/number-format" */).then(c => wrapFunctional(c.default || c))
export const Status = () => import('../../uicore/components/Status.vue' /* webpackChunkName: "components/status" */).then(c => wrapFunctional(c.default || c))
export const StatusNewDesign = () => import('../../uicore/components/StatusNewDesign.vue' /* webpackChunkName: "components/status-new-design" */).then(c => wrapFunctional(c.default || c))
export const AdminAdmins = () => import('../../uicore/components/Admin/Admins.vue' /* webpackChunkName: "components/admin-admins" */).then(c => wrapFunctional(c.default || c))
export const AdminApps = () => import('../../uicore/components/Admin/Apps.vue' /* webpackChunkName: "components/admin-apps" */).then(c => wrapFunctional(c.default || c))
export const AdminCalendar = () => import('../../uicore/components/Admin/Calendar.vue' /* webpackChunkName: "components/admin-calendar" */).then(c => wrapFunctional(c.default || c))
export const AdminEmployeeWS = () => import('../../uicore/components/Admin/EmployeeWS.vue' /* webpackChunkName: "components/admin-employee-w-s" */).then(c => wrapFunctional(c.default || c))
export const AdminEmployees = () => import('../../uicore/components/Admin/Employees.vue' /* webpackChunkName: "components/admin-employees" */).then(c => wrapFunctional(c.default || c))
export const AdminServices = () => import('../../uicore/components/Admin/Services.vue' /* webpackChunkName: "components/admin-services" */).then(c => wrapFunctional(c.default || c))
export const AdminWS = () => import('../../uicore/components/Admin/WS.vue' /* webpackChunkName: "components/admin-w-s" */).then(c => wrapFunctional(c.default || c))
export const ArmProfile = () => import('../../uicore/components/Arm/Profile.vue' /* webpackChunkName: "components/arm-profile" */).then(c => wrapFunctional(c.default || c))
export const ArmProfileSidebar = () => import('../../uicore/components/Arm/ProfileSidebar.vue' /* webpackChunkName: "components/arm-profile-sidebar" */).then(c => wrapFunctional(c.default || c))
export const BaseProfile = () => import('../../uicore/components/Base/Profile.vue' /* webpackChunkName: "components/base-profile" */).then(c => wrapFunctional(c.default || c))
export const DocDownloadAllBtn = () => import('../../uicore/components/Doc/DownloadAllBtn.vue' /* webpackChunkName: "components/doc-download-all-btn" */).then(c => wrapFunctional(c.default || c))
export const FAQPage = () => import('../../uicore/components/FAQ/Page.vue' /* webpackChunkName: "components/f-a-q-page" */).then(c => wrapFunctional(c.default || c))
export const FieldControlCatalog = () => import('../../uicore/components/FieldControl/Catalog.vue' /* webpackChunkName: "components/field-control-catalog" */).then(c => wrapFunctional(c.default || c))
export const FieldControlCatalogSelect = () => import('../../uicore/components/FieldControl/CatalogSelect.vue' /* webpackChunkName: "components/field-control-catalog-select" */).then(c => wrapFunctional(c.default || c))
export const FieldControlDate = () => import('../../uicore/components/FieldControl/Date.vue' /* webpackChunkName: "components/field-control-date" */).then(c => wrapFunctional(c.default || c))
export const FieldControlNumber = () => import('../../uicore/components/FieldControl/Number.vue' /* webpackChunkName: "components/field-control-number" */).then(c => wrapFunctional(c.default || c))
export const FieldControlText = () => import('../../uicore/components/FieldControl/Text.vue' /* webpackChunkName: "components/field-control-text" */).then(c => wrapFunctional(c.default || c))
export const ModalsAdminCreate = () => import('../../uicore/components/Modals/AdminCreate.vue' /* webpackChunkName: "components/modals-admin-create" */).then(c => wrapFunctional(c.default || c))
export const ModalsAppResults = () => import('../../uicore/components/Modals/AppResults.vue' /* webpackChunkName: "components/modals-app-results" */).then(c => wrapFunctional(c.default || c))
export const ModalsAppReturn = () => import('../../uicore/components/Modals/AppReturn.vue' /* webpackChunkName: "components/modals-app-return" */).then(c => wrapFunctional(c.default || c))
export const ModalsChangePassword = () => import('../../uicore/components/Modals/ChangePassword.vue' /* webpackChunkName: "components/modals-change-password" */).then(c => wrapFunctional(c.default || c))
export const ModalsChangePhone = () => import('../../uicore/components/Modals/ChangePhone.vue' /* webpackChunkName: "components/modals-change-phone" */).then(c => wrapFunctional(c.default || c))
export const ModalsChangePhoneEmail = () => import('../../uicore/components/Modals/ChangePhoneEmail.vue' /* webpackChunkName: "components/modals-change-phone-email" */).then(c => wrapFunctional(c.default || c))
export const ModalsConfirmModal = () => import('../../uicore/components/Modals/ConfirmModal.vue' /* webpackChunkName: "components/modals-confirm-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsConfirmReturn = () => import('../../uicore/components/Modals/ConfirmReturn.vue' /* webpackChunkName: "components/modals-confirm-return" */).then(c => wrapFunctional(c.default || c))
export const ModalsDocGenerate = () => import('../../uicore/components/Modals/DocGenerate.vue' /* webpackChunkName: "components/modals-doc-generate" */).then(c => wrapFunctional(c.default || c))
export const ModalsEmployeeAdd = () => import('../../uicore/components/Modals/EmployeeAdd.vue' /* webpackChunkName: "components/modals-employee-add" */).then(c => wrapFunctional(c.default || c))
export const ModalsEmployeeCreate = () => import('../../uicore/components/Modals/EmployeeCreate.vue' /* webpackChunkName: "components/modals-employee-create" */).then(c => wrapFunctional(c.default || c))
export const ModalsEmployeeEdit = () => import('../../uicore/components/Modals/EmployeeEdit.vue' /* webpackChunkName: "components/modals-employee-edit" */).then(c => wrapFunctional(c.default || c))
export const ModalsProfileSave = () => import('../../uicore/components/Modals/ProfileSave.vue' /* webpackChunkName: "components/modals-profile-save" */).then(c => wrapFunctional(c.default || c))
export const ModalsSelectAppPeriod = () => import('../../uicore/components/Modals/SelectAppPeriod.vue' /* webpackChunkName: "components/modals-select-app-period" */).then(c => wrapFunctional(c.default || c))
export const ModalsTSList = () => import('../../uicore/components/Modals/TSList.vue' /* webpackChunkName: "components/modals-t-s-list" */).then(c => wrapFunctional(c.default || c))
export const ModalsTableModal = () => import('../../uicore/components/Modals/TableModal.vue' /* webpackChunkName: "components/modals-table-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsTableViewModal = () => import('../../uicore/components/Modals/TableViewModal.vue' /* webpackChunkName: "components/modals-table-view-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsWithdrawAppModal = () => import('../../uicore/components/Modals/WithdrawAppModal.vue' /* webpackChunkName: "components/modals-withdraw-app-modal" */).then(c => wrapFunctional(c.default || c))
export const ProcessEmpsSelectBlock = () => import('../../uicore/components/Process/EmpsSelectBlock.vue' /* webpackChunkName: "components/process-emps-select-block" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldBlock = () => import('../../uicore/components/Process/FieldBlock.vue' /* webpackChunkName: "components/process-field-block" */).then(c => wrapFunctional(c.default || c))
export const ProcessMapBlock = () => import('../../uicore/components/Process/MapBlock.vue' /* webpackChunkName: "components/process-map-block" */).then(c => wrapFunctional(c.default || c))
export const ProcessViewBlock = () => import('../../uicore/components/Process/ViewBlock.vue' /* webpackChunkName: "components/process-view-block" */).then(c => wrapFunctional(c.default || c))
export const AdminControlDeleteApps = () => import('../../uicore/components/Admin/Control/DeleteApps.vue' /* webpackChunkName: "components/admin-control-delete-apps" */).then(c => wrapFunctional(c.default || c))
export const AdminControlDeleteDrafts = () => import('../../uicore/components/Admin/Control/DeleteDrafts.vue' /* webpackChunkName: "components/admin-control-delete-drafts" */).then(c => wrapFunctional(c.default || c))
export const ArmAppList = () => import('../../uicore/components/Arm/App/List.vue' /* webpackChunkName: "components/arm-app-list" */).then(c => wrapFunctional(c.default || c))
export const ArmAppMonitor = () => import('../../uicore/components/Arm/App/Monitor.vue' /* webpackChunkName: "components/arm-app-monitor" */).then(c => wrapFunctional(c.default || c))
export const ArmAppMonitorAppStep = () => import('../../uicore/components/Arm/App/MonitorAppStep.vue' /* webpackChunkName: "components/arm-app-monitor-app-step" */).then(c => wrapFunctional(c.default || c))
export const ArmAppProcess = () => import('../../uicore/components/Arm/App/Process.vue' /* webpackChunkName: "components/arm-app-process" */).then(c => wrapFunctional(c.default || c))
export const ArmServiceInfo = () => import('../../uicore/components/Arm/Service/Info.vue' /* webpackChunkName: "components/arm-service-info" */).then(c => wrapFunctional(c.default || c))
export const BaseAppMonitor = () => import('../../uicore/components/Base/App/Monitor.vue' /* webpackChunkName: "components/base-app-monitor" */).then(c => wrapFunctional(c.default || c))
export const BaseAppProcess = () => import('../../uicore/components/Base/App/Process.vue' /* webpackChunkName: "components/base-app-process" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldCatalog = () => import('../../uicore/components/Process/Field/Catalog.vue' /* webpackChunkName: "components/process-field-catalog" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldCheckbox = () => import('../../uicore/components/Process/Field/Checkbox.vue' /* webpackChunkName: "components/process-field-checkbox" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldDate = () => import('../../uicore/components/Process/Field/Date.vue' /* webpackChunkName: "components/process-field-date" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldGeo = () => import('../../uicore/components/Process/Field/Geo.vue' /* webpackChunkName: "components/process-field-geo" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldGeoCatalog = () => import('../../uicore/components/Process/Field/GeoCatalog.vue' /* webpackChunkName: "components/process-field-geo-catalog" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldLine = () => import('../../uicore/components/Process/Field/Line.vue' /* webpackChunkName: "components/process-field-line" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldNumber = () => import('../../uicore/components/Process/Field/Number.vue' /* webpackChunkName: "components/process-field-number" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldPoint = () => import('../../uicore/components/Process/Field/Point.vue' /* webpackChunkName: "components/process-field-point" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldPolygon = () => import('../../uicore/components/Process/Field/Polygon.vue' /* webpackChunkName: "components/process-field-polygon" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldRegistryCatalog = () => import('../../uicore/components/Process/Field/RegistryCatalog.vue' /* webpackChunkName: "components/process-field-registry-catalog" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldScreen = () => import('../../uicore/components/Process/Field/Screen.vue' /* webpackChunkName: "components/process-field-screen" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldStatic = () => import('../../uicore/components/Process/Field/Static.vue' /* webpackChunkName: "components/process-field-static" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldTable = () => import('../../uicore/components/Process/Field/Table.vue' /* webpackChunkName: "components/process-field-table" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldText = () => import('../../uicore/components/Process/Field/Text.vue' /* webpackChunkName: "components/process-field-text" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldMixin = () => import('../../uicore/components/Process/Field/mixin.js' /* webpackChunkName: "components/process-field-mixin" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
