import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6ed7ea3e = () => interopDefault(import('../uicore/pages/arm/index.vue' /* webpackChunkName: "pages/arm/index" */))
const _6af0fd82 = () => interopDefault(import('../uicore/pages/base/index.vue' /* webpackChunkName: "pages/base/index" */))
const _73a93ba0 = () => interopDefault(import('../uicore/pages/Test.vue' /* webpackChunkName: "pages/Test" */))
const _67c5e455 = () => interopDefault(import('../uicore/pages/arm/forgotPass.vue' /* webpackChunkName: "pages/arm/forgotPass" */))
const _db88a410 = () => interopDefault(import('../uicore/pages/arm/login.vue' /* webpackChunkName: "pages/arm/login" */))
const _25963310 = () => interopDefault(import('../uicore/pages/arm/profile.vue' /* webpackChunkName: "pages/arm/profile" */))
const _1fa083c6 = () => interopDefault(import('../uicore/pages/base/faq.vue' /* webpackChunkName: "pages/base/faq" */))
const _968fb128 = () => interopDefault(import('../uicore/pages/oldAppsList/applicant.vue' /* webpackChunkName: "pages/oldAppsList/applicant" */))
const _39dc6d98 = () => interopDefault(import('../uicore/pages/oldAppsList/employee.vue' /* webpackChunkName: "pages/oldAppsList/employee" */))
const _037782c9 = () => interopDefault(import('../uicore/pages/arm/application/income.vue' /* webpackChunkName: "pages/arm/application/income" */))
const _6ba5313e = () => interopDefault(import('../uicore/pages/arm/application/list.vue' /* webpackChunkName: "pages/arm/application/list" */))
const _86540006 = () => interopDefault(import('../uicore/pages/base/application/list.vue' /* webpackChunkName: "pages/base/application/list" */))
const _7ee896cb = () => interopDefault(import('../uicore/pages/base/auth/forgotPass.vue' /* webpackChunkName: "pages/base/auth/forgotPass" */))
const _77766c7c = () => interopDefault(import('../uicore/pages/base/auth/login.vue' /* webpackChunkName: "pages/base/auth/login" */))
const _7d33907c = () => interopDefault(import('../uicore/pages/base/auth/profile.vue' /* webpackChunkName: "pages/base/auth/profile" */))
const _8178404c = () => interopDefault(import('../uicore/pages/base/auth/register.vue' /* webpackChunkName: "pages/base/auth/register" */))
const _1f2e5ec5 = () => interopDefault(import('../uicore/pages/arm/admin/org/app.vue' /* webpackChunkName: "pages/arm/admin/org/app" */))
const _30091f3a = () => interopDefault(import('../uicore/pages/arm/admin/org/employee.vue' /* webpackChunkName: "pages/arm/admin/org/employee" */))
const _cbbfc94e = () => interopDefault(import('../uicore/pages/arm/admin/org/service.vue' /* webpackChunkName: "pages/arm/admin/org/service" */))
const _5399d242 = () => interopDefault(import('../uicore/pages/arm/admin/system/app.vue' /* webpackChunkName: "pages/arm/admin/system/app" */))
const _6b1a9e6d = () => interopDefault(import('../uicore/pages/arm/admin/system/calendar.vue' /* webpackChunkName: "pages/arm/admin/system/calendar" */))
const _092f92fe = () => interopDefault(import('../uicore/pages/arm/admin/system/control.vue' /* webpackChunkName: "pages/arm/admin/system/control" */))
const _2cda074d = () => interopDefault(import('../uicore/pages/arm/admin/system/list.vue' /* webpackChunkName: "pages/arm/admin/system/list" */))
const _78094fac = () => interopDefault(import('../uicore/pages/arm/admin/system/route.vue' /* webpackChunkName: "pages/arm/admin/system/route" */))
const _c1f3a5b2 = () => interopDefault(import('../uicore/pages/arm/application/view/asId.vue' /* webpackChunkName: "pages/arm/application/view/asId" */))
const _4a067509 = () => interopDefault(import('../uicore/pages/base/application/result/appId.vue' /* webpackChunkName: "pages/base/application/result/appId" */))
const _003d5444 = () => interopDefault(import('../uicore/pages/arm/admin/org/ws/employee/_empId.vue' /* webpackChunkName: "pages/arm/admin/org/ws/employee/_empId" */))
const _f100a9f4 = () => interopDefault(import('../uicore/pages/arm/admin/org/ws/_tsrId.vue' /* webpackChunkName: "pages/arm/admin/org/ws/_tsrId" */))
const _70ce0ddc = () => interopDefault(import('../uicore/pages/arm/application/monitor/_appId.vue' /* webpackChunkName: "pages/arm/application/monitor/_appId" */))
const _05b99288 = () => interopDefault(import('../uicore/pages/arm/application/process/_asId.vue' /* webpackChunkName: "pages/arm/application/process/_asId" */))
const _55069145 = () => interopDefault(import('../uicore/pages/arm/service/info/_tsrId.vue' /* webpackChunkName: "pages/arm/service/info/_tsrId" */))
const _4fe8ebae = () => interopDefault(import('../uicore/pages/arm/service/list/_parentId.vue' /* webpackChunkName: "pages/arm/service/list/_parentId" */))
const _7b41a511 = () => interopDefault(import('../uicore/pages/base/application/monitor/_appId.vue' /* webpackChunkName: "pages/base/application/monitor/_appId" */))
const _5dc58edd = () => interopDefault(import('../uicore/pages/base/application/process/_asId.vue' /* webpackChunkName: "pages/base/application/process/_asId" */))
const _d0a0d1c0 = () => interopDefault(import('../uicore/pages/base/auth/resetPass/_token.vue' /* webpackChunkName: "pages/base/auth/resetPass/_token" */))
const _d85c53b4 = () => interopDefault(import('../uicore/pages/base/service/info/_tsrId.vue' /* webpackChunkName: "pages/base/service/info/_tsrId" */))
const _8ee521a6 = () => interopDefault(import('../uicore/pages/base/service/list/_parentId.vue' /* webpackChunkName: "pages/base/service/list/_parentId" */))
const _6abb152a = () => interopDefault(import('../uicore/pages/arm/resetPass/_token.vue' /* webpackChunkName: "pages/arm/resetPass/_token" */))
const _487fd3f8 = () => interopDefault(import('../uicore/pages/base/check_doc/_fileId.vue' /* webpackChunkName: "pages/base/check_doc/_fileId" */))
const _ba3c5858 = () => interopDefault(import('../uicore/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _18eb34b2 = () => interopDefault(import('../modules/Wmsh/pages/applicant-cabinet/index.vue' /* webpackChunkName: "" */))
const _7c3f9a9a = () => interopDefault(import('../modules/Wmsh/pages/applicant-cabinet/animals/index.vue' /* webpackChunkName: "" */))
const _5a64ff78 = () => interopDefault(import('../modules/Wmsh/pages/applicant-cabinet/bank-details/index.vue' /* webpackChunkName: "" */))
const _27466fbe = () => interopDefault(import('../modules/Wmsh/pages/applicant-cabinet/crop-rotation/index.vue' /* webpackChunkName: "" */))
const _c2300444 = () => interopDefault(import('../modules/Wmsh/pages/applicant-cabinet/electronic-map-fields/index.vue' /* webpackChunkName: "" */))
const _091ae246 = () => interopDefault(import('../modules/Wmsh/pages/applicant-cabinet/employee-profile/index.vue' /* webpackChunkName: "" */))
const _0bb50868 = () => interopDefault(import('../modules/Wmsh/pages/applicant-cabinet/profile/index.vue' /* webpackChunkName: "" */))
const _9542edc6 = () => interopDefault(import('../modules/Wmsh/pages/applicant-cabinet/qoldau-historical-data/index.vue' /* webpackChunkName: "" */))
const _19edc7df = () => interopDefault(import('../modules/Wmsh/pages/applicant-cabinet/service-history/index.vue' /* webpackChunkName: "" */))
const _785632e5 = () => interopDefault(import('../modules/Wmsh/pages/applicant-cabinet/service-history/_app.vue' /* webpackChunkName: "" */))
const _6ba198b1 = () => interopDefault(import('../modules/Wmsh/pages/applicant-cabinet/services/index.vue' /* webpackChunkName: "" */))
const _4df0819b = () => interopDefault(import('../modules/Wmsh/pages/applicant-cabinet/territories/index.vue' /* webpackChunkName: "" */))
const _70e8afda = () => interopDefault(import('../modules/Wmsh/pages/applicant-cabinet/vehicles/index.vue' /* webpackChunkName: "" */))
const _c6a5417e = () => interopDefault(import('../modules/Wmsh/pages/arm/index.vue' /* webpackChunkName: "" */))
const _afcd597e = () => interopDefault(import('../modules/Wmsh/pages/arm/admin/index.vue' /* webpackChunkName: "" */))
const _287b9354 = () => interopDefault(import('../modules/Wmsh/pages/arm/admin/org/index.vue' /* webpackChunkName: "" */))
const _31ddcb96 = () => interopDefault(import('../modules/Wmsh/pages/arm/admin/org/addpaymaster.vue' /* webpackChunkName: "" */))
const _70904197 = () => interopDefault(import('../modules/Wmsh/pages/arm/admin/org/commission.vue' /* webpackChunkName: "" */))
const _5c50188e = () => interopDefault(import('../modules/Wmsh/pages/arm/admin/org/paymaster.vue' /* webpackChunkName: "" */))
const _5fb629d3 = () => interopDefault(import('../modules/Wmsh/pages/arm/admin/system/index.vue' /* webpackChunkName: "" */))
const _343f91ac = () => interopDefault(import('../modules/Wmsh/pages/arm/admin/system/invoice-for-admin.vue' /* webpackChunkName: "" */))
const _1ed0ed0d = () => interopDefault(import('../modules/Wmsh/pages/arm/admin/system/qoldau-agreements.vue' /* webpackChunkName: "" */))
const _976ae3ce = () => interopDefault(import('../modules/Wmsh/pages/arm/admin/system/qoldau.vue' /* webpackChunkName: "" */))
const _b414d534 = () => interopDefault(import('../modules/Wmsh/pages/arm/budget.vue' /* webpackChunkName: "" */))
const _221d0f5e = () => interopDefault(import('../modules/Wmsh/pages/arm/commission-request/index.vue' /* webpackChunkName: "" */))
const _614deac8 = () => interopDefault(import('../modules/Wmsh/pages/arm/commission-request/_crId.vue' /* webpackChunkName: "" */))
const _016e46a7 = () => interopDefault(import('../modules/Wmsh/pages/arm/esf.vue' /* webpackChunkName: "" */))
const _1edd3ff0 = () => interopDefault(import('../modules/Wmsh/pages/arm/iframe/index.vue' /* webpackChunkName: "" */))
const _f117fc10 = () => interopDefault(import('../modules/Wmsh/pages/arm/iframe/details.vue' /* webpackChunkName: "" */))
const _3c48e837 = () => interopDefault(import('../modules/Wmsh/pages/arm/iframe/gsm.vue' /* webpackChunkName: "" */))
const _113b46c4 = () => interopDefault(import('../modules/Wmsh/pages/arm/iframe/new_m.vue' /* webpackChunkName: "" */))
const _21ef565a = () => interopDefault(import('../modules/Wmsh/pages/arm/iframe/rate-payment.vue' /* webpackChunkName: "" */))
const _d5d3c9fe = () => interopDefault(import('../modules/Wmsh/pages/arm/iframe/rate-subsidies-doc.vue' /* webpackChunkName: "" */))
const _1a29261a = () => interopDefault(import('../modules/Wmsh/pages/arm/iframe/rate.vue' /* webpackChunkName: "" */))
const _4fa7c5bf = () => interopDefault(import('../modules/Wmsh/pages/arm/price_obsh.vue' /* webpackChunkName: "" */))
const _163a066b = () => interopDefault(import('../modules/Wmsh/pages/arm/qoldau.vue' /* webpackChunkName: "" */))
const _453ab52a = () => interopDefault(import('../modules/Wmsh/pages/arm/qoldaupercent.vue' /* webpackChunkName: "" */))
const _0878f421 = () => interopDefault(import('../modules/Wmsh/pages/arm/registry/index.vue' /* webpackChunkName: "" */))
const _3ba34730 = () => interopDefault(import('../modules/Wmsh/pages/arm/registry/all.vue' /* webpackChunkName: "" */))
const _ece85188 = () => interopDefault(import('../modules/Wmsh/pages/arm/registry/invoice.vue' /* webpackChunkName: "" */))
const _13cdeb90 = () => interopDefault(import('../modules/Wmsh/pages/arm/registry/onpay.vue' /* webpackChunkName: "" */))
const _0ac2bb35 = () => interopDefault(import('../modules/Wmsh/pages/arm/stat.vue' /* webpackChunkName: "" */))
const _3c69a4b2 = () => interopDefault(import('../modules/Wmsh/pages/arm/statistica.vue' /* webpackChunkName: "" */))
const _2d1de5a3 = () => interopDefault(import('../modules/Wmsh/pages/arm/unlockesf.vue' /* webpackChunkName: "" */))
const _1a023522 = () => interopDefault(import('../modules/Wmsh/pages/base/index.vue' /* webpackChunkName: "" */))
const _598908b6 = () => interopDefault(import('../modules/Wmsh/pages/base/budget.vue' /* webpackChunkName: "" */))
const _a18edbd6 = () => interopDefault(import('../modules/Wmsh/pages/base/dashbords/index.vue' /* webpackChunkName: "" */))
const _63b27f8e = () => interopDefault(import('../modules/Wmsh/pages/base/helper.vue' /* webpackChunkName: "" */))
const _33fb5b07 = () => interopDefault(import('../modules/Wmsh/pages/base/iframe/index.vue' /* webpackChunkName: "" */))
const _cf698d92 = () => interopDefault(import('../modules/Wmsh/pages/base/iframe/details.vue' /* webpackChunkName: "" */))
const _28b8d214 = () => interopDefault(import('../modules/Wmsh/pages/base/iframe/gsm.vue' /* webpackChunkName: "" */))
const _a8ae470a = () => interopDefault(import('../modules/Wmsh/pages/base/iframe/processing-of-products.vue' /* webpackChunkName: "" */))
const _771e3662 = () => interopDefault(import('../modules/Wmsh/pages/base/iframe/rate-subsidies-doc.vue' /* webpackChunkName: "" */))
const _56244f3b = () => interopDefault(import('../modules/Wmsh/pages/base/iframe/rate.vue' /* webpackChunkName: "" */))
const _280c0980 = () => interopDefault(import('../modules/Wmsh/pages/base/iframe/water-supply.vue' /* webpackChunkName: "" */))
const _6dd75b9a = () => interopDefault(import('../modules/Wmsh/pages/base/news.vue' /* webpackChunkName: "" */))
const _437fecaa = () => interopDefault(import('../modules/Wmsh/pages/base/qoldau.vue' /* webpackChunkName: "" */))
const _19502b60 = () => interopDefault(import('../modules/Wmsh/pages/base/registry/index.vue' /* webpackChunkName: "" */))
const _7f0d422f = () => interopDefault(import('../modules/Wmsh/pages/base/registry/all.vue' /* webpackChunkName: "" */))
const _06f04177 = () => interopDefault(import('../modules/Wmsh/pages/base/registry/onpay.vue' /* webpackChunkName: "" */))
const _35f26a48 = () => interopDefault(import('../modules/Wmsh/pages/base/service/index.vue' /* webpackChunkName: "" */))
const _65821b17 = () => interopDefault(import('../modules/Wmsh/pages/base/service/all.vue' /* webpackChunkName: "" */))
const _1f9c82f8 = () => interopDefault(import('../modules/Wmsh/pages/gissauth.vue' /* webpackChunkName: "" */))
const _56945834 = () => interopDefault(import('../modules/Wmsh/pages/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/arm",
    component: _6ed7ea3e,
    name: "arm"
  }, {
    path: "/base",
    component: _6af0fd82,
    name: "base"
  }, {
    path: "/Test",
    component: _73a93ba0,
    name: "Test"
  }, {
    path: "/arm/forgotPass",
    component: _67c5e455,
    name: "arm-forgotPass"
  }, {
    path: "/arm/login",
    component: _db88a410,
    name: "arm-login"
  }, {
    path: "/arm/profile",
    component: _25963310,
    name: "arm-profile"
  }, {
    path: "/base/faq",
    component: _1fa083c6,
    name: "base-faq"
  }, {
    path: "/oldAppsList/applicant",
    component: _968fb128,
    name: "oldAppsList-applicant"
  }, {
    path: "/oldAppsList/employee",
    component: _39dc6d98,
    name: "oldAppsList-employee"
  }, {
    path: "/arm/application/income",
    component: _037782c9,
    name: "arm-application-income"
  }, {
    path: "/arm/application/list",
    component: _6ba5313e,
    name: "arm-application-list"
  }, {
    path: "/base/application/list",
    component: _86540006,
    name: "base-application-list"
  }, {
    path: "/base/auth/forgotPass",
    component: _7ee896cb,
    name: "base-auth-forgotPass"
  }, {
    path: "/base/auth/login",
    component: _77766c7c,
    name: "base-auth-login"
  }, {
    path: "/base/auth/profile",
    component: _7d33907c,
    name: "base-auth-profile"
  }, {
    path: "/base/auth/register",
    component: _8178404c,
    name: "base-auth-register"
  }, {
    path: "/arm/admin/org/app",
    component: _1f2e5ec5,
    name: "arm-admin-org-app"
  }, {
    path: "/arm/admin/org/employee",
    component: _30091f3a,
    name: "arm-admin-org-employee"
  }, {
    path: "/arm/admin/org/service",
    component: _cbbfc94e,
    name: "arm-admin-org-service"
  }, {
    path: "/arm/admin/system/app",
    component: _5399d242,
    name: "arm-admin-system-app"
  }, {
    path: "/arm/admin/system/calendar",
    component: _6b1a9e6d,
    name: "arm-admin-system-calendar"
  }, {
    path: "/arm/admin/system/control",
    component: _092f92fe,
    name: "arm-admin-system-control"
  }, {
    path: "/arm/admin/system/list",
    component: _2cda074d,
    name: "arm-admin-system-list"
  }, {
    path: "/arm/admin/system/route",
    component: _78094fac,
    name: "arm-admin-system-route"
  }, {
    path: "/arm/application/view/asId",
    component: _c1f3a5b2,
    name: "arm-application-view-asId"
  }, {
    path: "/base/application/result/appId",
    component: _4a067509,
    name: "base-application-result-appId"
  }, {
    path: "/arm/admin/org/ws/employee/:empId?",
    component: _003d5444,
    name: "arm-admin-org-ws-employee-empId"
  }, {
    path: "/arm/admin/org/ws/:tsrId?",
    component: _f100a9f4,
    name: "arm-admin-org-ws-tsrId"
  }, {
    path: "/arm/application/monitor/:appId?",
    component: _70ce0ddc,
    name: "arm-application-monitor-appId"
  }, {
    path: "/arm/application/process/:asId?",
    component: _05b99288,
    name: "arm-application-process-asId"
  }, {
    path: "/arm/service/info/:tsrId?",
    component: _55069145,
    name: "arm-service-info-tsrId"
  }, {
    path: "/arm/service/list/:parentId?",
    component: _4fe8ebae,
    name: "arm-service-list-parentId"
  }, {
    path: "/base/application/monitor/:appId?",
    component: _7b41a511,
    name: "base-application-monitor-appId"
  }, {
    path: "/base/application/process/:asId?",
    component: _5dc58edd,
    name: "base-application-process-asId"
  }, {
    path: "/base/auth/resetPass/:token?",
    component: _d0a0d1c0,
    name: "base-auth-resetPass-token"
  }, {
    path: "/base/service/info/:tsrId?",
    component: _d85c53b4,
    name: "base-service-info-tsrId"
  }, {
    path: "/base/service/list/:parentId?",
    component: _8ee521a6,
    name: "base-service-list-parentId"
  }, {
    path: "/arm/resetPass/:token?",
    component: _6abb152a,
    name: "arm-resetPass-token"
  }, {
    path: "/base/check_doc/:fileId?",
    component: _487fd3f8,
    name: "base-check_doc-fileId"
  }, {
    path: "/",
    component: _ba3c5858,
    name: "index"
  }, {
    path: "/applicant-cabinet",
    component: _18eb34b2,
    name: "applicant-cabinet"
  }, {
    path: "/applicant-cabinet/animals",
    component: _7c3f9a9a,
    name: "applicant-cabinet-animals"
  }, {
    path: "/applicant-cabinet/bank-details",
    component: _5a64ff78,
    name: "applicant-cabinet-bank-details"
  }, {
    path: "/applicant-cabinet/crop-rotation",
    component: _27466fbe,
    name: "applicant-cabinet-crop-rotation"
  }, {
    path: "/applicant-cabinet/electronic-map-fields",
    component: _c2300444,
    name: "applicant-cabinet-electronic-map-fields"
  }, {
    path: "/applicant-cabinet/employee-profile",
    component: _091ae246,
    name: "applicant-cabinet-employee-profile"
  }, {
    path: "/applicant-cabinet/profile",
    component: _0bb50868,
    name: "applicant-cabinet-profile"
  }, {
    path: "/applicant-cabinet/qoldau-historical-data",
    component: _9542edc6,
    name: "applicant-cabinet-qoldau-historical-data"
  }, {
    path: "/applicant-cabinet/service-history",
    component: _19edc7df,
    name: "applicant-cabinet-service-history"
  }, {
    path: "/applicant-cabinet/service-history/:app",
    component: _785632e5,
    name: "applicant-cabinet-service-history-:app"
  }, {
    path: "/applicant-cabinet/services",
    component: _6ba198b1,
    name: "applicant-cabinet-services"
  }, {
    path: "/applicant-cabinet/territories",
    component: _4df0819b,
    name: "applicant-cabinet-territories"
  }, {
    path: "/applicant-cabinet/vehicles",
    component: _70e8afda,
    name: "applicant-cabinet-vehicles"
  }, {
    path: "/arm",
    component: _c6a5417e,
    name: "arm"
  }, {
    path: "/arm/admin",
    component: _afcd597e,
    name: "arm-admin"
  }, {
    path: "/arm/admin/org",
    component: _287b9354,
    name: "arm-admin-org"
  }, {
    path: "/arm/admin/org/addpaymaster",
    component: _31ddcb96,
    name: "arm-admin-org-addpaymaster"
  }, {
    path: "/arm/admin/org/commission",
    component: _70904197,
    name: "arm-admin-org-commission"
  }, {
    path: "/arm/admin/org/paymaster",
    component: _5c50188e,
    name: "arm-admin-org-paymaster"
  }, {
    path: "/arm/admin/system",
    component: _5fb629d3,
    name: "arm-admin-system"
  }, {
    path: "/arm/admin/system/invoice-for-admin",
    component: _343f91ac,
    name: "arm-admin-system-invoice-for-admin"
  }, {
    path: "/arm/admin/system/qoldau-agreements",
    component: _1ed0ed0d,
    name: "arm-admin-system-qoldau-agreements"
  }, {
    path: "/arm/admin/system/qoldau",
    component: _976ae3ce,
    name: "arm-admin-system-qoldau"
  }, {
    path: "/arm/budget",
    component: _b414d534,
    name: "arm-budget"
  }, {
    path: "/arm/commission-request",
    component: _221d0f5e,
    name: "arm-commission-request"
  }, {
    path: "/arm/commission-request/:crId",
    component: _614deac8,
    name: "arm-commission-request-:crId"
  }, {
    path: "/arm/esf",
    component: _016e46a7,
    name: "arm-esf"
  }, {
    path: "/arm/iframe",
    component: _1edd3ff0,
    name: "arm-iframe"
  }, {
    path: "/arm/iframe/details",
    component: _f117fc10,
    name: "arm-iframe-details"
  }, {
    path: "/arm/iframe/gsm",
    component: _3c48e837,
    name: "arm-iframe-gsm"
  }, {
    path: "/arm/iframe/new_m",
    component: _113b46c4,
    name: "arm-iframe-new_m"
  }, {
    path: "/arm/iframe/rate-payment",
    component: _21ef565a,
    name: "arm-iframe-rate-payment"
  }, {
    path: "/arm/iframe/rate-subsidies-doc",
    component: _d5d3c9fe,
    name: "arm-iframe-rate-subsidies-doc"
  }, {
    path: "/arm/iframe/rate",
    component: _1a29261a,
    name: "arm-iframe-rate"
  }, {
    path: "/arm/price_obsh",
    component: _4fa7c5bf,
    name: "arm-price_obsh"
  }, {
    path: "/arm/qoldau",
    component: _163a066b,
    name: "arm-qoldau"
  }, {
    path: "/arm/qoldaupercent",
    component: _453ab52a,
    name: "arm-qoldaupercent"
  }, {
    path: "/arm/registry",
    component: _0878f421,
    name: "arm-registry"
  }, {
    path: "/arm/registry/all",
    component: _3ba34730,
    name: "arm-registry-all"
  }, {
    path: "/arm/registry/invoice",
    component: _ece85188,
    name: "arm-registry-invoice"
  }, {
    path: "/arm/registry/onpay",
    component: _13cdeb90,
    name: "arm-registry-onpay"
  }, {
    path: "/arm/stat",
    component: _0ac2bb35,
    name: "arm-stat"
  }, {
    path: "/arm/statistica",
    component: _3c69a4b2,
    name: "arm-statistica"
  }, {
    path: "/arm/unlockesf",
    component: _2d1de5a3,
    name: "arm-unlockesf"
  }, {
    path: "/base",
    component: _1a023522,
    name: "base"
  }, {
    path: "/base/budget",
    component: _598908b6,
    name: "base-budget"
  }, {
    path: "/base/dashbords",
    component: _a18edbd6,
    name: "base-dashbords"
  }, {
    path: "/base/helper",
    component: _63b27f8e,
    name: "base-helper"
  }, {
    path: "/base/iframe",
    component: _33fb5b07,
    name: "base-iframe"
  }, {
    path: "/base/iframe/details",
    component: _cf698d92,
    name: "base-iframe-details"
  }, {
    path: "/base/iframe/gsm",
    component: _28b8d214,
    name: "base-iframe-gsm"
  }, {
    path: "/base/iframe/processing-of-products",
    component: _a8ae470a,
    name: "base-iframe-processing-of-products"
  }, {
    path: "/base/iframe/rate-subsidies-doc",
    component: _771e3662,
    name: "base-iframe-rate-subsidies-doc"
  }, {
    path: "/base/iframe/rate",
    component: _56244f3b,
    name: "base-iframe-rate"
  }, {
    path: "/base/iframe/water-supply",
    component: _280c0980,
    name: "base-iframe-water-supply"
  }, {
    path: "/base/news",
    component: _6dd75b9a,
    name: "base-news"
  }, {
    path: "/base/qoldau",
    component: _437fecaa,
    name: "base-qoldau"
  }, {
    path: "/base/registry",
    component: _19502b60,
    name: "base-registry"
  }, {
    path: "/base/registry/all",
    component: _7f0d422f,
    name: "base-registry-all"
  }, {
    path: "/base/registry/onpay",
    component: _06f04177,
    name: "base-registry-onpay"
  }, {
    path: "/base/service",
    component: _35f26a48,
    name: "base-service"
  }, {
    path: "/base/service/all",
    component: _65821b17,
    name: "base-service-all"
  }, {
    path: "/gissauth",
    component: _1f9c82f8,
    name: "gissauth"
  }, {
    path: "/",
    component: _56945834
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
