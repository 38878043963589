export default function ({ redirect, $auth, route }) {
    if (!$auth.loggedIn) {
        redirect({
            path: "/base/auth/login",
            query: { redirect: route.fullPath }
        })
    } else if (![1, 2].includes($auth.user.role)) {
        redirect('/base/auth/login')
    }
}
