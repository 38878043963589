//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SidebarMenuBadge',
  props: {
    badge: {
      type: Object,
      default: () => {}
    }
  }
}
