import Vue from 'vue';
import {
  SET_MAP,
  ADD_INTERACTION,
  CLEAR_INTERACTION_TYPE
} from './mutation-types'

export default {
  [SET_MAP](state, map) {
    state.map = map;
  },
  [ADD_INTERACTION](state, { type, interaction }) {
    state.interactions[type] = [
      ...(state.interactions[type] || []),
      interaction
    ];
  },
  [CLEAR_INTERACTION_TYPE](state, type) {
    Vue.delete(state.interactions, type);
  }
}
