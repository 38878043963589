export default {
  namespaced:true,
  state: () => ({
    digitalFieldsData: [],
    digitalFields: [],
    hasFilters: false,
    searchingValue: '',
    pagination: {
      current_page: 1,
      per_page: 15,
      total: 0,
      from: 0,
      to: 0,
      last_page: 0,
    },
    loading: false,
    error: null,
  }),
  getters: {
    loading: state => state.loading,
    error: state => state.error,
    digitalFields: (state) => state.digitalFields,
    pagination: (state) => {
      let total = state.digitalFields.length
      return {
        current_page: state.pagination.current_page,
        per_page: 15,
        total,
        from: (state.pagination.current_page - 1) * state.pagination.per_page,
        to: state.pagination.current_page * state.pagination.per_page,
        last_page: Math.ceil(total / state.pagination.per_page),
      }
    },
    searchingValue: state => state.searchingValue,
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_DIGITAL_FIELDS_DATA(state, data) {
      state.digitalFieldsData = data;
    },
    SET_DIGITAL_FIELDS(state, data) {
      state.digitalFields = data
    },
    SET_PAGE(state, page) {
      state.pagination.current_page = page
    },
    SET_SEARCHING_VALUE: (state, value) => state.searchingValue = value,
    SET_ERROR(state, error) {
      state.error = error;
    }
  },
  actions: {
    async getDigitalFields({ commit, state }) {
      commit('SET_LOADING', true)
      commit('SET_ERROR', null)
      try {
        const {data} = await this.$axios.get('/api/wmsh/shep_info/kgs_data')
        const { fields } = data
        await commit('SET_DIGITAL_FIELDS_DATA', fields || []);
        await commit('SET_DIGITAL_FIELDS', fields || []);
      } catch (e) {
        commit('SET_ERROR', e?.response?.data?.message || e?.response?.message || e?.message || "Произошла ошибка повторите попытку позднее")
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async filter({commit, state}) {
      let { digitalFieldsData, searchingValue } = state

      let items = digitalFieldsData;

      if(searchingValue) {
        items = items.filter(row => {
          const numbers = row['Ident_numb'].toString().toLowerCase();
          const searchTerm = searchingValue.toLowerCase();

          return numbers.includes(searchTerm)
        });
      }
      await commit('SET_DIGITAL_FIELDS', items);
    },
    setSearchingValue: ({commit}, value) => commit('SET_SEARCHING_VALUE', value),
    resetFilter: ({ commit, state }) => {
      commit('SET_ERROR', null)
      commit('SET_SEARCHING_VALUE', null)
      commit('SET_DIGITAL_FIELDS', state.digitalFieldsData)
    }
  }
}