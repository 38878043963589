//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BIconChevronRight } from "bootstrap-vue";

export default {
  name: "ApplicantCabinetSidebar",
  components: {BIconChevronRight},
  data() {
    return {
      refreshKey: 0,
      avatarImageUrl: null,
      menu: [
        {
          header: "Main Navigation",
          hiddenOnCollapse: true,
        },
        {
          href: "/applicant-cabinet/profile",
          title: "Мой профиль",
          icon: "user-avatar",
        },
        {
          href: '/applicant-cabinet/service-history',
          title: 'История получения услуг',
          icon: 'document-bullet-list'
        },
        {
          href: "/applicant-cabinet/qoldau-historical-data",
          title: "Исторические данные Qoldau",
          icon: "history-line",
        },
        {
          href: "/applicant-cabinet/bank-details",
          title: "Банковские  реквизиты",
          icon: "billing",
        },
        {
          href: "/applicant-cabinet/animals",
          title: "Сельскохозяйственные животные",
          icon: "cow",
        },
        {
          href: "/applicant-cabinet/vehicles",
          title: "Сельскохозяйственная техника",
          icon: "tractor",
        },
        {
          href: "/applicant-cabinet/territories",
          title: "Земельные участки",
          icon: "farm",
        },
        {
          href: "/applicant-cabinet/electronic-map-fields",
          title: "Электронная карта полей",
          icon: "drone",
        },
        {
          href: "/applicant-cabinet/crop-rotation",
          title: "Севооборот",
          icon: "crop-rotation",
        },
      ],
    };
  },
  computed:{
    userAvatarPath() {
      return `${process.env.BASE_URL}/api/wmsh/profile/photo/${this.$store.$auth.user._id}`;
    },
  },
  methods: {
    openAvatarChoose() {
      this.$refs.photo.click()
    },
    async updateAvatar() {
      if(this.$refs.photo){
        let formData = new FormData()

        formData.append('avatar', this.$refs.photo.files[0])

        try {
          await this.$axios.post('api/wmsh/profile/upload_photo', formData)
        } catch (error) {
          if(error.response){
            console.log(error.message)
          }else{
            console.log(error)
          }
        } finally {
          this.refreshKey++;
        }
      }
    }
  }
};
