//
//
//
//
//
//
//

export default {
  data() {
    return {
      projectName: process.env.projectName,
    };
  },
  head() {
    return {
      link: [{ rel: "stylesheet", href: "/css/base.css" }],
      title: this.projectName[this.$i18n.locale],
      meta: [
        {
          hid: 'google',
          name: 'google',
          value: 'notranslate'
        }
      ]
    };
  },
};
