import { render, staticRenderFns } from "./layouts.applicant_cabinet.9f12dc7c.vue?vue&type=template&id=bc6d4e5e&"
import script from "./layouts.applicant_cabinet.9f12dc7c.vue?vue&type=script&lang=js&"
export * from "./layouts.applicant_cabinet.9f12dc7c.vue?vue&type=script&lang=js&"
import style0 from "./layouts.applicant_cabinet.9f12dc7c.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseHeader: require('/home/tadmin/var/www/msh/ui/modules/Wmsh/components/Base/Header.vue').default,ApplicantCabinetSidebar: require('/home/tadmin/var/www/msh/ui/modules/Wmsh/components/ApplicantCabinet/Sidebar.vue').default,ApplicantCabinetNotificationSidebar: require('/home/tadmin/var/www/msh/ui/modules/Wmsh/components/ApplicantCabinet/NotificationSidebar.vue').default})
