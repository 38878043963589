import moment from "moment/moment";

export default {
  namespaced:true,
  state: () => ({
    animalsData: [],
    animals: [],
    searchingValue: '',
    hasFilters: false,
    selectedAppPeriod: '',
    filterByType: null,
    filterByMast: null,
    filterByAgeFrom: null,
    filterByAgeTo: null,
    pagination: {
      current_page: 1,
      per_page: 15,
      total: 0,
      from: 0,
      to: 0,
      last_page: 0,
    },
    loading: false,
    error: null
  }),
  getters: {
    animals: (state) => state.animals,
    pagination: (state) => {
      let total = state.animals.length
      return {
        current_page: state.pagination.current_page,
        per_page: 15,
        total,
        from: (state.pagination.current_page - 1) * state.pagination.per_page,
        to: state.pagination.current_page * state.pagination.per_page,
        last_page: Math.ceil(total / state.pagination.per_page),
      }
    },
    searchingValue: state => state.searchingValue,
    filterByType: state => state.filterByType,
    filterByMast: state => state.filterByMast,
    filterByAgeFrom: state => state.filterByAgeFrom,
    filterByAgeTo: state => state.filterByAgeTo,
    availableTypes: (state, getters) => {
      return [...new Set(state.animalsData.map(item => item[getters.animalKindFieldName]))]
    },
    availableMasts: (state, getters) => {
      return [...new Set(state.animalsData.map(item => item[getters.animalMastFieldName]))]
    },
    animalKindFieldName: (state, getters) => {
      return 'animalKind';
    },
    animalMastFieldName: (state, getters) => {
      return 'mast';
    },
    hasFilters: state => state.hasFilters,
    loading: state => state.loading,
    error: state => state.error
  },
  mutations:{
    SET_ANIMALS_DATA(state, data) {
      state.animalsData = data;
    },
    SET_ANIMALS(state, data) {
      state.animals = data
    },
    SET_PAGE(state, page) {
      state.pagination.current_page = page
    },
    SET_FILTER_BY_KIND: (state, kind) => state.filterByType = kind,
    SET_FILTER_BY_MAST: (state, mast) => state.filterByMast = mast,
    SET_FILTER_BY_AGE_FROM: (state, ageFrom) => state.filterByAgeFrom = ageFrom,
    SET_FILTER_BY_AGE_TO: (state, ageTo) => state.filterByAgeTo = ageTo,
    SET_SEARCHING_VALUE: (state, value) => state.searchingValue = value,
    SET_HAS_FILTERS: (state, value) => state.hasFilters = value,
    SET_LOADING: (state, loading) => state.loading = loading,
    SET_ERROR: (state, error) => state.error = error
  },
  actions: {
      async getAnimals({ commit, state }) {
        commit('SET_LOADING', true)
        commit('SET_ERROR', null)
        try {
          const { data } = await this.$axios.get('/api/wmsh/shep_info/animals')
          const { animals } = data
          await commit('SET_ANIMALS_DATA', animals || []);
          await commit('SET_ANIMALS', animals || []);
        } catch (e) {
          commit('SET_ERROR', e?.response?.data?.message || e?.response?.message || e?.message || "Произошла ошибка повторите попытку позднее")
          await commit('SET_ANIMALS_DATA', []);
          await commit('SET_ANIMALS', []);
        } finally {
          commit('SET_LOADING', false)
        }
      },
      async filter({ commit, state, getters }) {
        let { filterByType, filterByMast, filterByAgeFrom, filterByAgeTo, animalsData, searchingValue } = state

        let animals = animalsData;

        if(filterByType || filterByMast || filterByAgeFrom || filterByAgeFrom) {
          commit("SET_HAS_FILTERS", true)
        }

        if (filterByType) {
          animals = animals.filter(item => item[getters.animalKindFieldName] === filterByType)
        }

        if (filterByMast) {
          animals = animals.filter(item => item[getters.animalMastFieldName] === filterByMast)
        }

        if(filterByAgeFrom) {
          animals = animals.filter(item => moment().diff(item.birthDate, 'months') > filterByAgeFrom)
        }

        if(filterByAgeTo) {
          animals = animals.filter(item => moment().diff(item.birthDate, 'months') < filterByAgeFrom)
        }

        if (searchingValue) {
          animals = animals.filter(row => {
            const numbers = row['inj'].toString().toLowerCase();
            const searchTerm = searchingValue.toLowerCase();

            return numbers.includes(searchTerm)
          });
        }

        await commit('SET_ANIMALS', animals);
      },
      setKindFilterValue: ({ commit }, value) => commit('SET_FILTER_BY_KIND', value),
      setMastFilterValue: ({ commit }, value) => commit('SET_FILTER_BY_MAST', value),
      setAgeFromFilterValue: ({ commit }, value) => commit('SET_FILTER_BY_AGE_FROM', value),
      setAgeToFilterValue: ({ commit }, value) => commit('SET_FILTER_BY_AGE_TO', value),
      setSearchingValue: ({ commit }, value) => commit('SET_SEARCHING_VALUE', value),
      resetFilter: ({ commit, state }) => {
        commit('SET_ERROR', null)
        commit('SET_HAS_FILTERS', false)
        commit('SET_FILTER_BY_KIND', null)
        commit('SET_FILTER_BY_MAST', null)
        commit('SET_FILTER_BY_AGE_FROM', null)
        commit('SET_FILTER_BY_AGE_TO', null)
        commit('SET_ANIMALS', state.animalsData)
      }
    }
}
