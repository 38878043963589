import applicantCabinet from "./store/applicantCabinet";
export default function ({ store }) {
  store.registerModule('applicantCabinet', applicantCabinet)

  // base header
  store.commit("setBaseHeaderMenuItem", {
    to: "/base/registry/all",
    name: "registry.all",
  });

  store.commit("setBaseHeaderMenuItem", {
    to: "/base/registry/onpay",
    name: "registry.approved",
  });

  store.commit("setBaseHeaderMenuItem", {
    to: "/base/budget",
    name: "budget.menuItem",
  });

  store.commit("setBaseHeaderMenuItem", {
    to: "/base/iframe/gsm",
    name: "header.gsm",
  });

  // store.commit("setBaseHeaderMenuItem", {
  //   to: "/base/dashbords",
  //   name: "dashbords.title",
  // });
  store.commit("setBaseHeaderMenuItem", {
    to: "/base/qoldau",
    name: "header.qoldau",
  });


  // arm header
  store.commit("setArmHeaderMenuItem", {
    to: "/arm/registry/all",
    name: "registry.all",
  });

  store.commit("setArmHeaderMenuItem", {
    to: "/arm/registry/onpay",
    name: "registry.approved",
  });

  store.commit("setArmHeaderMenuItem", {
    to: "/arm/budget",
    name: "budget.menuItem",
  });

  store.commit("setArmHeaderMenuItem", {
    to: "/arm/registry/invoice",
    name: "header.invoice",
  });

  store.commit("setArmHeaderMenuItem", {
    to: "/arm/commission-request",
    name: "header.commissionRequests",
  });

  store.commit("setArmHeaderMenuItem", {
    to: "/arm/qoldau",
    name: "header.qoldau",
  });

  store.commit("setArmHeaderMenuItem", {
    to: "/arm/qoldaupercent",
    name: "header.qoldaupercent",
  });

  store.commit("setBaseHeaderMenuItem", {
    to: "/base/helper",
    name: "registry.helper",
  });

  store.commit("setArmHeaderMenuItem", {
    to: "/arm/iframe/gsm",
    name: "header.gsm",
  });

  store.commit("setArmHeaderMenuItem", {
    to: "/arm/iframe/new_m",
    name: "header.welcome_page",
  });

  store.commit("setArmHeaderMenuItem", {
    to: "/arm/stat",
    name: "header.stat",
  });

  
  // arm user menu
  store.commit("setArmUserMenuItem", {
    to: "/arm/admin/org/paymaster",
    name: "admin.menu.paymasterSettings",
    condition: 'orgAdmin',
    order: 3
  });

  store.commit("setArmUserMenuItem", {
    to: "/arm/admin/org/commission",
    name: "admin.menu.commissionSettings",
    condition: 'orgAdmin',
    order: 4
  });

  store.commit("setArmUserMenuItem", {
    to: "/arm/esf",
    name: "admin.checkEsf",
    condition: user => user?.is_system_admin || user?.is_org_admin,
    order: 10
   });

  store.commit("setArmHeaderMenuItem", {
    to: "/arm/price_obsh",
    name: "header.priceObsh",
  });

  store.commit("setArmUserMenuItem", {
    to: "/arm/unlockesf",
    name: "admin.unlockEsf",
    condition: 'orgAdmin',
    order: 11
   });
}
