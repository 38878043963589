export default  {
  namespaced:true,
  state: () => ({
    apps: [],
    searchingValue: null,
    selectedAppPeriod: '',
    paginationActivePage: '',
    paginationPagesCount: '',
    pagination: {
      current_page: 1,
      per_page: 15,
      total: 1,
      from: 1,
      to: 1,
      last_page: 1,
    },
    loading: false,
    error: null,
  }),

  getters: {
    apps: (state) => state.apps,
    pagination: (state) => state.pagination,
    period: (state) => state.selectedAppPeriod,
    searchingValue: state => state.searchingValue,
    loading: state => state.loading,
    error: state => state.error,
  },
  mutations: {
    SET_APPS(state, data) {
      state.apps = data
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    },
    SET_PAGE(state, page) {
      state.pagination.current_page = page
    },
    SET_APP_FILTER_PERIOD(state, period) {
      state.selectedAppPeriod = period
    },
    SET_APP_FILTER_SEARCH(state, searchQuery) {
      state.searchingValue = searchQuery
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_ERROR(state, error) {
      state.error = error
    }
  },
  actions: {
    async getApps({ commit, state }) {
      commit("SET_LOADING", true);
      commit("SET_ERROR", null);
      try {
        let params  = `?page=${state.pagination.current_page}`

        if(state.selectedAppPeriod.start && state.selectedAppPeriod.end) {
          params += `&period[start]=${state.selectedAppPeriod.start}&period[end]=${state.selectedAppPeriod.end}`
        }

        if(state.searchingValue) {
          params += `&search=${state.searchingValue}`
        }

        const {data} = await this.$axios.get(`api/app/list/applicant/all${params}`)

        commit('SET_APPS', data.data);
        commit('SET_PAGINATION', data.meta);
      } catch (e) {
        commit('SET_ERROR', e?.response?.data?.message || e?.response?.message || e?.message || "Произошла ошибка повторите попытку позднее")
      } finally {
        commit("SET_LOADING", false)
      }
    },

    setAppFilterPeriod({commit}, payload) {
      commit('SET_APP_FILTER_PERIOD', payload)
    },

    setAppFilterSearch({commit}, payload) {
      commit('SET_APP_FILTER_SEARCH', payload)
    }
  }
}