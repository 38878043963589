import {
  INIT_MAP,
  REMOVE_INTERACTION_BY_TYPE
} from "./action-types";
import {
  SET_MAP,
  CLEAR_INTERACTION_TYPE
} from "./mutation-types";

import olInitMap from "~/assets/js/olInitMap";

export default {
  async [INIT_MAP]({ commit }, { el }) {
    let map = olInitMap({ el });
    commit(SET_MAP, map);
  },
  [REMOVE_INTERACTION_BY_TYPE]({ commit, state }, { type }) {
    state.interactions[type]?.forEach(
      i => state.map.removeInteraction(i)
    );
    commit(CLEAR_INTERACTION_TYPE, type);
  }
};
