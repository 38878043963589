export default {
  namespaced:true,
  state: () => ({
    territoriesData: [],
    territories: [],
    searchingValue: '',
    pagination: {
      current_page: 1,
      per_page: 15,
      total: 0,
      from: 0,
      to: 0,
      last_page: 1,
    },
    loading: false,
    error: null
  }),
  getters: {
    loading: state => state.loading,
    error: state => state.error,
    territories: (state) => state.territories,
    pagination: (state) => {
      let total = state.territories.length
      return {
        current_page: state.pagination.current_page,
        per_page: 15,
        total,
        from: (state.pagination.current_page - 1) * state.pagination.per_page,
        to: state.pagination.current_page * state.pagination.per_page,
        last_page: Math.ceil(total / state.pagination.per_page),
      }
    },
    searchingValue: state => state.searchingValue,
    territoryPurposeFieldName: (state, getters) => {
      let suffix =  getters.get_lang ? "KZ" : "RU";
      return `Purpose_${suffix}`
    },
    territoryAddressFieldName: (state, getters) => {
      let suffix =  getters.get_lang ? "KZ" : "RU";
      return `Address_${suffix}`
    },
  },
  mutations: {
    SET_TERRITORIES_DATA(state, data) {
      state.territoriesData = data;
    },
    SET_TERRITORIES(state, data) {
      state.territories = data
    },
    SET_PAGE(state, page) {
      state.pagination.current_page = page
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_SEARCHING_VALUE: (state, value) => state.searchingValue = value,
    SET_ERROR: (state, error) => state.error = error
  },
  actions:{
    async getTerritories({ commit }) {
      commit('SET_LOADING', true)
      commit('SET_ERROR', null)
      try {
        const {data} = await this.$axios.get('/api/wmsh/shep_info/territories')
        const { lands } = data
        await commit('SET_TERRITORIES_DATA', lands || []);
        await commit('SET_TERRITORIES', lands || []);
      } catch (e) {
        commit('SET_ERROR', e?.response?.data?.message || e?.response?.message || e?.message || "Произошла ошибка повторите попытку позднее")
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async filter({commit, state}) {
      let { searchingValue, territoriesData } = state

      let items = territoriesData;

      if(searchingValue) {
        items = items.filter(row => {
          const numbers = row['Kad_number'].toString().toLowerCase();
          const searchTerm = searchingValue.toLowerCase();

          return numbers.includes(searchTerm)
        });
      }

      await commit('SET_TERRITORIES', items);
    },
    setSearchingValue: ({commit}, value) => commit('SET_SEARCHING_VALUE', value),
  }
}