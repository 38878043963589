export default {
  namespaced:true,
  state: () => ({
    vehiclesData: [],
    vehicles: [],
    searchingValue: '',
    filterByModel: null,
    filterByRegDate: null,
    filterByPower: null,
    hasFilters: false,
    pagination: {
      current_page: 1,
      per_page: 15,
      total: 0,
      from: 0,
      to: 0,
      last_page: 1,
    },
    loading: false,
    error: null
  }),
  getters: {
    loading: state => state.loading,
    vehicles: (state) => state.vehicles,
    pagination: (state) => {
      let total = state.vehicles.length
      return {
        current_page: state.pagination.current_page,
        per_page: 15,
        total,
        from: (state.pagination.current_page - 1) * state.pagination.per_page,
        to: state.pagination.current_page * state.pagination.per_page,
        last_page: Math.ceil(total / state.pagination.per_page),
      }
    },
    searchingValue: state => state.searchingValue,
    filterByModel: state => state.filterByModel,
    filterByRegDate: state => state.filterByRegDate,
    filterByPower: state => state.filterByPower,
    availableModels: (state) => {
      return [...new Set(state.vehiclesData.map(item => item.model))]
    },
    availablePowers: (state) => {
      return [...new Set(state.vehiclesData.map(item => item.power))]
    },
    hasFilters: state => state.hasFilters,
    error: state => state.error
  },
  mutations: {
    SET_VEHICLES_DATA(state, data) {
      state.vehiclesData = data;
    },
    SET_VEHICLES(state, data) {
      state.vehicles = data
    },
    SET_PAGE(state, page) {
      state.pagination.current_page = page
    },
    SET_FILTER_BY_MODEL: (state, value) => state.filterByModel = value,
    SET_FILTER_BY_REG_DATE: (state, value) => state.filterByRegDate = value,
    SET_FILTER_BY_POWER: (state, value) => state.filterByPower = value,
    SET_SEARCHING_VALUE: (state, value) => state.searchingValue = value,
    SET_HAS_FILTERS: (state, value) => state.hasFilters = value,
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_ERROR(state, error) {
      state.error = error
    }
  },
  actions: {
    async getVehicles({ commit }) {
      commit('SET_LOADING', true)
      commit('SET_ERROR', null)
      try {
        const { data } = await this.$axios.get('/api/wmsh/shep_info/vehicles')

        await commit('SET_VEHICLES_DATA', data || []);
        await commit('SET_VEHICLES', data || []);
      } catch (e) {
        commit('SET_ERROR', e?.response?.data?.message || e?.response?.message || e?.message || "Произошла ошибка повторите попытку позднее")
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async filter({ commit, state, getters }) {
      let { filterByModel, filterByPower, filterByRegDate, vehiclesData, searchingValue } = state

      let vehicles = vehiclesData;

      if(filterByModel || filterByPower || filterByRegDate) {
        commit("SET_HAS_FILTERS", true)
      }

      if (filterByModel) {
        vehicles = vehicles.filter(item => item.model === filterByModel)
      }

      if (filterByPower) {
        vehicles = vehicles.filter(item => item.power == filterByPower)
      }

      if (filterByRegDate) {
        // vehicles = vehicles.filter(item => )
      }

      if (searchingValue) {
        vehicles = vehicles.filter(row => {
          const numbers = row['techPassportNumber'].toString().toLowerCase();
          const searchTerm = searchingValue.toLowerCase();

          return numbers.includes(searchTerm)
        });
      }

      await commit('SET_VEHICLES', vehicles);
    },

    setModelFilterValue: ({ commit }, value) => commit('SET_FILTER_BY_MODEL', value),
    setRegDateFilterValue: ({ commit }, value) => commit('SET_FILTER_BY_REG_DATE', value),
    setPowerFilterValue: ({ commit }, value) => commit('SET_FILTER_BY_POWER', value),
    setSearchingValue: ({ commit }, value) => commit('SET_SEARCHING_VALUE', value),
    resetFilter: ({ commit, state }) => {
      commit('SET_ERROR', null)
      commit('SET_HAS_FILTERS', false)
      commit('SET_FILTER_BY_MODEL', null)
      commit('SET_FILTER_BY_REG_DATE', null)
      commit('SET_FILTER_BY_POWER', null)
      commit('SET_VEHICLES', state.vehiclesData)
    }
  }
}