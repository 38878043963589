//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      localeName: {
        ru: "Рус",
        kk: "Қаз",
      },
      showLangList: false,
      mainMenu: false,
      authMenu: false,
      account: {
        kk: "Жеке кабинет",
        ru: "Личный кабинет",
      },
      projectName: {
        ru: "Министерство сельского хозяйства Республики Казахстан",
        kk: "Қазақстан Республикасы Ауыл шаруашылығы министрлігі",
      },
      projectEmail: process.env.PROJECT_EMAIL,
      projectPhone: process.env.PROJECT_PHONE,
    };
  },
  created() {
    const onClickOutside = (e) => {
      this.authMenu = this.$el.contains(e.target) && this.authMenu;
    };
    document.addEventListener("click", onClickOutside);
    this.$on("hook:beforeDestroy", () =>
      document.removeEventListener("click", onClickOutside)
    );
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales;
    },
    currentLocale() {
      return this.$i18n.locales.find((i) => i.code === this.$i18n.locale);
    },
    menuItems() {
      return this.$store.state.headerBase;
    },
    isBaseRoute() {
      return this.$route.name == "base";
    },
  },
  methods: {
    chooseLang() {
      this.showLangList = !this.showLangList;
    },
    async logout() {
      await this.$auth.logout();
      this.$router.push("/base/auth/login");
    },
    redirectToLogin() {
      this.$router.push({
        path: "/base/auth/login",
        query: { redirect: this.$route.fullPath },
      });
    },
    redirectToRegister() {
      this.$router.push({
        path: "/base/auth/register",
        query: { redirect: this.$route.fullPath },
      });
    },
    changeLocale(newLocale) {
      this.$i18n.setLocale(newLocale);
      this.$nuxt.$emit("changeLocale", newLocale);
      this.showLangList = false;
    },
  },
};
