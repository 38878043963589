import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=43d1476a&scoped=true&"
import script from "./Sidebar.vue?vue&type=script&lang=js&"
export * from "./Sidebar.vue?vue&type=script&lang=js&"
import style0 from "./Sidebar.vue?vue&type=style&index=0&id=43d1476a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43d1476a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ApplicantCabinetIconsIcon: require('/home/tadmin/var/www/msh/ui/modules/Wmsh/components/ApplicantCabinet/Icons/Icon.vue').default,ApplicantCabinetSidebarMenu: require('/home/tadmin/var/www/msh/ui/modules/Wmsh/components/ApplicantCabinet/Sidebar/SidebarMenu.vue').default})
