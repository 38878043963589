//
//
//
//
//
//
//
//
//
//

export default {
  middleware: ["isApplicant"],
  head() {
    return {
      link: [
        {
          rel: "stylesheet",
          href: "//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue-icons.min.css"
        },
      ],
    };
  },
};
