//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      projectName: {
        ru: "Министерство сельского хозяйства Республики Казахстан",
        kk: "Қазақстан Республикасы Ауыл шаруашылығы министрлігі",
      },
      projectEmail: process.env.PROJECT_EMAIL,
      projectPhone: process.env.PROJECT_PHONE,
    };
  },
};
